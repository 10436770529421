import get from 'lodash/get';

import { GET_MENU_RESPONSE } from 'actions/menus';

const initialState = {};

const addMealOptionGroupsForMenu = (state, menuSlug, menu) => ({
  ...state,
  [menuSlug]: menu.mealOptionGroups,
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MENU_RESPONSE:
      return addMealOptionGroupsForMenu(state, action.params.menuSlug, action.response.menu);
    default:
      return state;
  }
};

export const selectMealOptionGroupsForMenu = (state, menuSlug) => get(state, menuSlug, []);
export const selectMealOptionGroupsForMenuAndMeal = (state, menuSlug, mealId) =>
  selectMealOptionGroupsForMenu(state, menuSlug).filter(el => el.mealId === mealId) || [];
export const selectMealOptionGroup = (state, menuSlug, mealId, mealOptionGroupId) =>
  selectMealOptionGroupsForMenuAndMeal(state, menuSlug, mealId).find(
    mog => mog.id === mealOptionGroupId
  );

import get from 'lodash/get';

import { GET_MENU_REQUEST, GET_MENU_RESPONSE, GET_MENU_ERROR } from 'actions/menus';

const initialState = {};
const uninitializedStatus = Object.freeze({
  needsInitialization: true,
  isLoading: false,
  isError: false,
});

const menuRequest = (state, menuSlug) => ({
  ...state,
  [menuSlug]: {
    ...state[menuSlug],
    needsInitialization: false,
    isLoading: true,
  },
});

const menuSuccess = (state, menuSlug) => ({
  ...state,
  [menuSlug]: {
    ...state[menuSlug],
    needsInitialization: false,
    isLoading: false,
    isError: false,
  },
});

const menuError = (state, menuSlug) => ({
  ...state,
  [menuSlug]: {
    ...state[menuSlug],
    needsInitialization: false,
    isLoading: false,
    isError: true,
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MENU_REQUEST:
      return menuRequest(state, action.params.menuSlug);
    case GET_MENU_RESPONSE:
      return menuSuccess(state, action.params.menuSlug);
    case GET_MENU_ERROR:
      return menuError(state, action.params.menuSlug);
    default:
      return state;
  }
};

const selectMenuStatus = (state, menuSlug) => state[menuSlug] || uninitializedStatus;
export const needsInitialization = (state, menuSlug) =>
  get(selectMenuStatus(state, menuSlug), 'needsInitialization', true);
export const isMenuLoading = (state, menuSlug) =>
  get(selectMenuStatus(state, menuSlug), 'isLoading', false);
export const isMenuError = (state, menuSlug) =>
  get(selectMenuStatus(state, menuSlug), 'isError', false);

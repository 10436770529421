import get from 'lodash/get';

import { GET_SUGGESTED_PAIRINGS_RESPONSE } from 'actions/suggestedPairings';

const initialState = {};

const addSuggestedPairingsForMenu = (state, menuSlug, suggestedPairings) => {
  return {
    ...state,
    [menuSlug]: suggestedPairings,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SUGGESTED_PAIRINGS_RESPONSE:
      return addSuggestedPairingsForMenu(
        state,
        action.response.menuSlug,
        action.response.suggestedPairings
      );
    default:
      return state;
  }
};

export const selectSuggestedPairingsForMenu = (state, menuSlug) => get(state, menuSlug, []);
export const selectSelectedSuggestedPairingsForMenu = (state, menuSlug) =>
  selectSuggestedPairingsForMenu(state, menuSlug).filter(
    pairing => pairing.suggestedPairing.isSelected === true
  ) || [];
export const selectSelectedSuggestedPairingForMenuAndHostMeal = (state, menuSlug, hostMealId) => {
  const pairingForHostMeal = selectSuggestedPairingsForMenu(state, menuSlug).find(
    pairing => pairing.hostMealId === hostMealId
  );

  if (!pairingForHostMeal || !pairingForHostMeal.suggestedPairing.isSelected) return;
  return pairingForHostMeal.suggestedPairing;
};

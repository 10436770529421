import {
  UPDATE_REVIEW_COMMENT_FOR_COOKBOOK_REQUEST,
  UPDATE_REVIEW_COMMENT_FOR_COOKBOOK_RESPONSE,
  UPDATE_REVIEW_COMMENT_FOR_COOKBOOK_ERROR,
  CLOSE_REVIEW_COMMENT,
} from 'actions/comments';
import { ADD_REVIEW_FOR_COOKBOOK_RESPONSE } from 'actions/reviews';

const startState = {
  showCommentBox: false,
  mealSelectionId: null,
  isError: false,
  isSubmitted: false,
  submitPending: false,
};

export default (state = startState, action) => {
  const { mealSelectionId, type } = action;

  switch (type) {
    case ADD_REVIEW_FOR_COOKBOOK_RESPONSE:
      return {
        ...startState,
        showCommentBox: true,
        mealSelectionId,
      };
    case CLOSE_REVIEW_COMMENT:
      return startState;
    case UPDATE_REVIEW_COMMENT_FOR_COOKBOOK_REQUEST:
      return {
        ...state,
        isError: false,
        submitPending: true,
      };
    case UPDATE_REVIEW_COMMENT_FOR_COOKBOOK_RESPONSE:
      return mealSelectionId === state.mealSelectionId
        ? {
            ...state,
            isError: false,
            isSubmitted: true,
            submitPending: false,
          }
        : state;
    case UPDATE_REVIEW_COMMENT_FOR_COOKBOOK_ERROR:
      return {
        ...state,
        submitPending: false,
        isError: true,
      };
    default:
      return state;
  }
};

import { ACCEPT, DISMISS } from 'actions/dismissals';
import { LOCAL_STORAGE_LOAD } from 'actions/localStorage';

const defaultState = {};

export default (state = defaultState, action) => {
  const { type, data, key } = action;

  switch (type) {
    case LOCAL_STORAGE_LOAD:
      return data.dismissals || state;
    case ACCEPT:
      return {
        ...state,
        [key]: false,
      };
    case DISMISS:
      return {
        ...state,
        [key]: true,
      };
    default:
      return state;
  }
};

export const selectDismissals = state => state.dismissals;

export const selectIsDismissed = (state, key) => Boolean(selectDismissals(state)[key]);

import sortBy from 'lodash/sortBy';

import {
  GET_TASTE_PROFILE_REQUEST,
  GET_TASTE_PROFILE_RESPONSE,
  GET_TASTE_PROFILE_ERROR,
  UPDATE_TASTE_PROFILE_REQUEST,
  UPDATE_TASTE_PROFILE_RESPONSE,
  UPDATE_TASTE_PROFILE_ERROR,
} from 'actions/tasteProfile';

const initialState = {
  fetching: false,
  error: null,
  allergies: [],
  preferences: [],
  updating: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TASTE_PROFILE_REQUEST:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case GET_TASTE_PROFILE_RESPONSE:
      return {
        ...state,
        fetching: false,
        allergies: action.response.tasteProfiles.allergies,
        preferences: action.response.tasteProfiles.preferences,
      };
    case GET_TASTE_PROFILE_ERROR:
      return {
        ...state,
        fetching: false,
        error: true,
      };
    case UPDATE_TASTE_PROFILE_REQUEST:
      return {
        ...state,
        updating: true,
        error: null,
      };
    case UPDATE_TASTE_PROFILE_RESPONSE:
      return {
        ...state,
        updating: false,
        allergies: action.response.tasteProfiles.allergies,
        preferences: action.response.tasteProfiles.preferences,
      };

    case UPDATE_TASTE_PROFILE_ERROR:
      return {
        ...state,
        updating: false,
        error: true,
      };
    default:
      return state;
  }
};

export const selectTasteProfileAllergies = state => sortBy(state.allergies, 'name');
export const selectTasteProfilePreferences = state => sortBy(state.preferences, 'id');
export const selectTasteProfileLoading = state => state.fetching;
export const selectTasteProfileError = state => state.error;
export const selectTasteProfileUpdating = state => state.updating;

import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_FAVORITES = 'GET_FAVORITES';
export const GET_FAVORITES_REQUEST = 'GET_FAVORITES_REQUEST';
export const GET_FAVORITES_RESPONSE = 'GET_FAVORITES_RESPONSE';
export const GET_FAVORITES_ERROR = 'GET_FAVORITES_ERROR';

export const getFavorites = () =>
  makeApiRequest({ requestType: GET_FAVORITES, method: RequestService.getFavorites });

export const ADD_FAVORITE = 'ADD_FAVORITE';
export const ADD_FAVORITE_REQUEST = 'ADD_FAVORITE_REQUEST';
export const ADD_FAVORITE_RESPONSE = 'ADD_FAVORITE_RESPONSE';
export const ADD_FAVORITE_ERROR = 'ADD_FAVORITE_ERROR';

export const addFavorite = params =>
  makeApiRequest({
    requestType: ADD_FAVORITE,
    method: RequestService.addFavorite,
    params,
  });

export const REMOVE_FAVORITE = 'REMOVE_FAVORITE';
export const REMOVE_FAVORITE_REQUEST = 'REMOVE_FAVORITE_REQUEST';
export const REMOVE_FAVORITE_RESPONSE = 'REMOVE_FAVORITE_RESPONSE';
export const REMOVE_FAVORITE_ERROR = 'REMOVE_FAVORITE_ERROR';

export const removeFavorite = params =>
  makeApiRequest({
    requestType: REMOVE_FAVORITE,
    method: RequestService.removeFavorite,
    params,
  });

export class FetchTypeError extends TypeError {}

export class FetchJsonError extends Error {
  constructor(error, response, responseBody) {
    super();

    this.error = error;
    this.response = response;
    this.responseBody = responseBody;
    this.name = this.getErrorName();
    this.message = this.getMessage();
  }

  getContentType() {
    try {
      return this.response.headers.get('content-type');
    } catch {
      return '';
    }
  }

  getResponseStatus() {
    return this.response.status;
  }

  getResponseOk() {
    return this.response.ok;
  }

  getOriginalMessage() {
    return this.error.message;
  }

  getErrorName() {
    return 'FetchJsonError';
  }

  getBaseMessage() {
    return this.responseBody.slice(0, 1000);
  }

  getMessage() {
    const message = `(Code: ${this.response.status}) - ${this.getBaseMessage()} `;
    return message;
  }
}

class CouldNotBeDisplayedError extends FetchJsonError {
  getBaseMessage() {
    return 'Page could not be displayed';
  }

  getErrorName() {
    return 'CouldNotBeDisplayedError';
  }
}

class RequestQueuingError extends FetchJsonError {
  getBaseMessage() {
    return 'Website is under heavy load';
  }

  getErrorName() {
    return 'RequestQueuingError';
  }
}

class CloudflareUnknownError extends FetchJsonError {
  getBaseMessage() {
    return 'Cloudflare error: Web server is returning an unknown error';
  }

  getErrorName() {
    return 'CloudflareUnknownError';
  }
}

class CloudflareTimeoutError extends FetchJsonError {
  getBaseMessage() {
    return 'Cloudflare error: Connection timed out';
  }

  getErrorName() {
    return 'CloudflareTimeoutError';
  }
}

class CloudflareSSLHandshakeError extends FetchJsonError {
  getBaseMessage() {
    return 'Cloudflare error: SSL handshake failed';
  }

  getErrorName() {
    return 'CloudflareSSLHandshakeError';
  }
}

class CloudflareCaptchaError extends FetchJsonError {
  getBaseMessage() {
    return 'Cloudflare error: API request returned "Attention Required!" captcha';
  }

  getErrorName() {
    return 'CloudflareCaptchaError';
  }
}

class ServerHangupError extends FetchJsonError {
  getBaseMessage() {
    return 'Server Hangup';
  }

  getErrorName() {
    return 'ServerHangupError';
  }
}

class RequestTooLargeError extends FetchJsonError {
  getBaseMessage() {
    return 'Request header or cookie too large.';
  }

  getErrorName() {
    return 'RequestTooLargeError';
  }
}

class PlatformError extends FetchJsonError {
  getBaseMessage() {
    return 'Home Chef platform error: "having some problems in the kitchen"';
  }

  getErrorName() {
    return 'PlatformError';
  }
}

class NonJsonResponseError extends FetchJsonError {
  getErrorName() {
    return 'NonJsonResponseError';
  }
}

const errorClasses = [
  {
    regex: /This website is under heavy load.*queue full.*/,
    errorClass: RequestQueuingError,
  },
  {
    regex: /Page could not be displayed/,
    errorClass: CouldNotBeDisplayedError,
  },
  {
    regex: /520: Web server is returning an unknown error/,
    errorClass: CloudflareUnknownError,
  },
  {
    regex: /522: Connection timed out/,
    errorClass: CloudflareTimeoutError,
  },
  {
    regex: /525: SSL handshake failed/,
    errorClass: CloudflareSSLHandshakeError,
  },
  {
    regex: /Attention Required!/,
    errorClass: CloudflareCaptchaError,
  },
  {
    regex: /Server Hangup/,
    errorClass: ServerHangupError,
  },
  {
    regex: /400 Request Header Or Cookie Too Large/,
    errorClass: RequestTooLargeError,
  },
  {
    regex: /platform_error\.html/,
    errorClass: PlatformError,
  },
];

export function getJsonParseErrorClass(responseBody) {
  const errorClassEntry = errorClasses.find(errorClass => responseBody.match(errorClass.regex));
  if (errorClassEntry) return errorClassEntry.errorClass;
  else return NonJsonResponseError;
}

import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';

import { UPDATE_REVIEW_COMMENT_RESPONSE } from 'actions/comments';
import {
  GET_PENDING_REVIEWS_REQUEST,
  GET_PENDING_REVIEWS_RESPONSE,
  ADD_REVIEW_RESPONSE,
  ADD_REVIEW_REQUEST,
  ADD_REVIEW_ERROR,
  REVIEW_CHANGE,
  UPDATE_REVIEW_ERROR,
  UPDATE_REVIEW_REQUEST,
  UPDATE_REVIEW_RESPONSE,
} from 'actions/reviews';

const transformReviewsResponse = response => keyBy(response.reviews, 'meal.id');

const transformUpdateReviewResponse = response =>
  omit(response, ['meal', 'menu', 'weeklyBasket', 'rating']);

export default (state = {}, action) => {
  const { mealId, params, review, response, type } = action;

  switch (type) {
    case GET_PENDING_REVIEWS_REQUEST:
      return {
        ...state,
        isError: false,
      };
    case GET_PENDING_REVIEWS_RESPONSE:
      return {
        ...state,
        ...transformReviewsResponse(response),
      };
    case REVIEW_CHANGE:
      return {
        ...state,
        [mealId]: {
          ...state[mealId],
          pendingChanges: review,
        },
      };
    case ADD_REVIEW_REQUEST:
    case UPDATE_REVIEW_REQUEST:
      return {
        ...state,
        isError: false,
        [params.mealId]: {
          ...state[params.mealId],
          isLoading: true,
        },
      };
    case ADD_REVIEW_ERROR:
    case UPDATE_REVIEW_ERROR:
      return {
        ...state,
        isError: true,
        [params.mealId]: {
          ...omit(state[params.mealId], 'pendingChanges'),
          isLoading: false,
        },
      };
    case ADD_REVIEW_RESPONSE:
      return {
        ...state,
        [response.meal.id]: response,
      };
    case UPDATE_REVIEW_COMMENT_RESPONSE:
    case UPDATE_REVIEW_RESPONSE:
      return {
        ...state,
        [response.meal.id]: transformUpdateReviewResponse(response),
      };
    default:
      return state;
  }
};

export const selectReview = (state, mealId) => state[mealId];

import { fetchWrapper, handleResponse, hcUrl, buildOpts } from 'services/RequestService/helpers';

const getBehavioralDiscounts = async ({ params: { menuSlug } }) => {
  const response = await fetchWrapper(
    hcUrl(`/menus/${menuSlug}/behavioral_discounts`),
    buildOpts()
  );
  return handleResponse(response);
};

export { getBehavioralDiscounts };

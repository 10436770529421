/* The Cookbook API returns less meal properties than the rest of the API
 * It names some properties slightly differently, like prep_minutes vs. prep_time
 */

export const cookbookMeal = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  prep: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  subtitle: AirbnbPropTypes.or([PropTypes.string, AirbnbPropTypes.explicitNull()]).isRequired,
});

export const DELIVERED_BASKET_STATUS = 'DELIVERED';
export const PROCESSED_BASKET_STATUS = 'PROCESSED';
export const SHIPPED_BASKET_STATUS = 'SHIPPED';
export const BELOW_MINIMUM_BASKET_STATUS = 'BELOW MINIMUM';
export const SKIPPED_BASKET_STATUS = 'SKIPPED';
export const SCHEDULED_BASKET_STATUS = 'SCHEDULED';
export const ACTIVE_BASKET_STATUS = 'ACTIVE';
export const UNDELIVERABLE_BASKET_STATUS = 'UNDELIVERABLE';
export const DONATED_BASKET_STATUS = 'DONATED';

export const basketStatus = PropTypes.oneOf([
  DELIVERED_BASKET_STATUS,
  DONATED_BASKET_STATUS,
  PROCESSED_BASKET_STATUS,
  SHIPPED_BASKET_STATUS,
  BELOW_MINIMUM_BASKET_STATUS,
  SKIPPED_BASKET_STATUS,
  SCHEDULED_BASKET_STATUS,
  ACTIVE_BASKET_STATUS,
  UNDELIVERABLE_BASKET_STATUS,
  DONATED_BASKET_STATUS,
]);

export const mealDisplayUnit = PropTypes.shape({
  increment: PropTypes.number.isRequired,
  singular: PropTypes.string.isRequired,
  plural: PropTypes.string.isRequired,
  isStandard: PropTypes.bool.isRequired,
  maxOrderableQuantity: PropTypes.number.isRequired,
});

const primaryLabelData = PropTypes.shape({
  labelText: PropTypes.string.isRequired,
  descriptionText: PropTypes.string,
  textColorHexCode: PropTypes.string.isRequired,
});

export const meal = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  prepMinutes: PropTypes.string,
  photo: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  subtitle: AirbnbPropTypes.or([PropTypes.string, AirbnbPropTypes.explicitNull()]).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  servings: PropTypes.number,
  primaryLabel: PropTypes.string,
  secondaryLabel: PropTypes.string,
  mealAddonId: PropTypes.string,
  mealAddonName: PropTypes.string,
  displayUnit: mealDisplayUnit.isRequired,
  isDonation: PropTypes.bool.isRequired,
  primaryLabelData,
});

export const lineItemMeal = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  prepMinutes: PropTypes.string,
  photo: PropTypes.string.isRequired,
  subtitle: AirbnbPropTypes.or([PropTypes.string, AirbnbPropTypes.explicitNull()]).isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  servings: PropTypes.number,
  primaryLabel: PropTypes.string,
  secondaryLabel: PropTypes.string,
  displayUnit: mealDisplayUnit.isRequired,
  primaryLabelData,
});

const addressProperties = {
  streetAddress: PropTypes.string.isRequired,
  streetAddress2: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  id: PropTypes.string,
};

export const address = PropTypes.shape(addressProperties);

export const basketAddress = PropTypes.shape({
  ...addressProperties,
  default: PropTypes.bool.isRequired,
});

export const basket = PropTypes.shape({
  pastBasket: PropTypes.bool.isRequired,
  futureBasket: PropTypes.bool.isRequired,
  scheduled: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
  meals: PropTypes.arrayOf(PropTypes.object),
  displayStatus: basketStatus,
  belowMinimum: PropTypes.bool.isRequired,
  address: address.isRequired,
});

export const barebonesBasket = PropTypes.shape({
  id: PropTypes.string.isRequired,
  menu: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
});

export const mealOption = PropTypes.shape({
  id: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
  mealOptionGroupId: PropTypes.string.isRequired,
  priceModifierCents: PropTypes.number.isRequired,
});

export const mealOptionGroup = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  mealId: PropTypes.string,
  mealOptions: PropTypes.arrayOf(mealOption),
});

export const mealOptionSelection = PropTypes.shape({
  mealOptionGroupId: PropTypes.string.isRequired,
  mealOptionId: PropTypes.string.isRequired,
});

export const mealAddon = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
});

export const optIn = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const optInSelection = PropTypes.shape({
  id: PropTypes.string.isRequired,
});

export const userModel = PropTypes.shape({
  address,
  mealAddonSelections: PropTypes.arrayOf(mealAddon),
  name: PropTypes.string.isRequired,
});

export const calendarWeek = PropTypes.shape({
  deliveryDay: PropTypes.string,
  status: PropTypes.string.isRequired,
  days: PropTypes.arrayOf(PropTypes.object).isRequired, // array of MomentJS moments
  weekOf: PropTypes.string.isRequired, // date string representing Monday of week, unique key
  basketSlug: PropTypes.string.isRequired, // slug for the corresponding week's basket
});

export const calendarMonth = PropTypes.shape({
  monthName: PropTypes.string.isRequired,
  weeks: PropTypes.arrayOf(calendarWeek),
});

export const calendar = PropTypes.arrayOf(calendarMonth);

export const UNPAUSE_ACTION = 'unpause';
export const SHOW_MODAL_ACTION = 'show_modal';
export const SHOW_JANUARY_RESTART_MODAL_ACTION = 'show_january_restart_modal';

export const ICON_PAUSE = 'pause';
export const ICON_CREDIT_CARD = 'credit_card';
export const ICON_NEW = 'new_badge';

export const message = PropTypes.shape({
  action: PropTypes.string,
  actionText: PropTypes.string,
  description: PropTypes.string,
  displayRule: PropTypes.string,
  heading: PropTypes.string.isRequired,
  photo: PropTypes.string,
  placement: PropTypes.string.isRequired,
  priority: PropTypes.number.isRequired,
  url: PropTypes.string,
});

export const review = PropTypes.shape({
  id: PropTypes.string,
  recipeRating: PropTypes.number,
  comment: PropTypes.string,
  mealSelectionId: PropTypes.string,
});

export const reviewComment = PropTypes.shape({
  showCommentBox: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  submitPending: PropTypes.bool.isRequired,
  mealSelectionId: AirbnbPropTypes.or([PropTypes.string, AirbnbPropTypes.explicitNull()]),
  mealId: AirbnbPropTypes.or([PropTypes.string, AirbnbPropTypes.explicitNull()]),
});

export const mealNote = PropTypes.shape({
  id: PropTypes.string.isRequired,
  mealId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

/**
 * Formik doesn't expose its injected props prop-types; declaring them here
 * manually so we can re-use them easily.
 *
 * From https://jaredpalmer.com/formik/docs/api/formik#formik-render-methods-and-props
 */
export const formikForm = {
  dirty: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  isValidating: PropTypes.bool.isRequired,
  resetForm: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  status: PropTypes.any,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  validateForm: PropTypes.func.isRequired,
  validateField: PropTypes.func.isRequired,
};

export const refType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({ current: PropTypes.PropTypes.any }),
]);

export const shoppingListIngredient = PropTypes.shape({
  name: PropTypes.string.isRequired,
  measure: PropTypes.string.isRequired,
  pantryIngredient: PropTypes.bool.isRequired,
  totalQuantity: PropTypes.number.isRequired,
  displayQuantity: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  meals: PropTypes.array.isRequired,
});

export const shoppingListMeal = PropTypes.shape({
  id: PropTypes.string.isRequired,
  slug: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
      measure: PropTypes.string.isRequired,
    })
  ),
});

export const shoppingListServings = PropTypes.objectOf(PropTypes.number);

export const NOT_ENROLLED_BETA_BASKET_STATUS = 'NOT ENROLLED';
export const ENROLLED_BETA_BASKET_STATUS = 'ENROLLED';
export const WAITLISTED_BETA_BASKET_STATUS = 'ON THE WAITLIST';
export const PROCESSED_BETA_BASKET_STATUS = 'PROCESSED';
export const DELIVERED_BETA_BASKET_STATUS = 'DELIVERED';
export const SHIPPED_BETA_BASKET_STATUS = 'SHIPPED';

const betaBasketStatus = PropTypes.oneOf([
  NOT_ENROLLED_BETA_BASKET_STATUS,
  ENROLLED_BETA_BASKET_STATUS,
  WAITLISTED_BETA_BASKET_STATUS,
  PROCESSED_BETA_BASKET_STATUS,
  DELIVERED_BETA_BASKET_STATUS,
  SHIPPED_BETA_BASKET_STATUS,
]);

export const betaBasket = PropTypes.shape({
  deliveryDate: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  meals: PropTypes.array.isRequired,
  menuId: PropTypes.string.isRequired,
  servings: PropTypes.number.isRequired,
  status: betaBasketStatus,
  loading: PropTypes.bool,
  trackingLink: PropTypes.string.isRequired,
  reviewable: PropTypes.bool.isRequired,
  reviewLink: PropTypes.string.isRequired,
});

export const menuCategory = PropTypes.shape({
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

export const searchParamsTypes = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.string,
  PropTypes.number,
]);

export const searchParams = PropTypes.objectOf(searchParamsTypes);

export const minimalPastOrder = PropTypes.shape({
  id: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  deliveryDate: PropTypes.string.isRequired,
  chargedDate: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
});

export const pastReceipt = PropTypes.shape({
  deliveryDate: PropTypes.string.isRequired,
  donated: PropTypes.bool.isRequired,
  voucherCodeUsed: PropTypes.string,
  trackingNumber: PropTypes.string,
  trackingLink: PropTypes.string,
  refundAmount: PropTypes.string,
  subtotal: PropTypes.string.isRequired,
  shipping: PropTypes.string,
  credit: PropTypes.string,
  salesTax: PropTypes.string,
  total: PropTypes.string.isRequired,
  menu: PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  address: address.isRequired,
  meals: PropTypes.arrayOf(meal).isRequired,
});

export const mealBadge = PropTypes.shape({
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const rectangularMealBadge = PropTypes.shape({
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const pauseReasonsMeal = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  subtitle: AirbnbPropTypes.or([PropTypes.string, AirbnbPropTypes.explicitNull()]).isRequired,
  primaryLabelData,
});

export const favorite = PropTypes.shape({
  id: PropTypes.string.isRequired,
  mealId: PropTypes.string.isRequired,
  mealConcept: PropTypes.string.isRequired,
});

export const behavioralDiscount = PropTypes.shape({
  promotionText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  discountTriggered: PropTypes.bool.isRequired,
});

export const sendABoxMenu = PropTypes.shape({
  id: PropTypes.string.isRequired,
  donatable: PropTypes.bool.isRequired,
  endsAt: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  weekOfDate: PropTypes.string.isRequired,
});

export const quickFilters = PropTypes.shape({
  displayName: PropTypes.string.isRequired,
  iconUrl: PropTypes.string.isRequired,
  toMealFilter: PropTypes.shape({
    filter: PropTypes.string.isRequired,
    value: PropTypes.object.isRequired,
  }).isRequired,
});

import {
  fetchWrapper,
  handleResponse,
  hcUrl,
  buildOpts,
  transformBetaBasketResponse,
  transformBetaBasketsResponse,
} from 'services/RequestService/helpers';

export const getBetaBaskets = async () => {
  const response = await fetchWrapper(
    hcUrl('/recipe_testing_society/beta_baskets'),
    buildOpts({
      method: 'GET',
    })
  );
  return handleResponse(response, transformBetaBasketsResponse);
};

export const getBetaBasket = async ({ params: { menuId } }) => {
  const response = await fetchWrapper(
    hcUrl(`/recipe_testing_society/menus/${menuId}/beta_basket`),
    buildOpts({ method: 'GET' })
  );
  return handleResponse(response, transformBetaBasketResponse);
};

export const optInBetaBox = async ({ params: { menuId } }) => {
  const response = await fetchWrapper(
    hcUrl(`/recipe_testing_society/menus/${menuId}/beta_basket/opt_in`),
    buildOpts({ method: 'GET' })
  );
  return handleResponse(response, transformBetaBasketResponse);
};

export const optOutBetaBox = async ({ params: { menuId } }) => {
  const response = await fetchWrapper(
    hcUrl(`/recipe_testing_society/menus/${menuId}/beta_basket/opt_out`),
    buildOpts({ method: 'GET' })
  );
  return handleResponse(response, transformBetaBasketResponse);
};

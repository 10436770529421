import { camelizeKeys, decamelizeKeys } from 'humps';
// We ignore 8 characters b/c don't want to camelize
// or decamelize our hashed ids {id: "4t3e5Pjd", ....}`

export const camelize = obj =>
  camelizeKeys(obj, (key, convert) => {
    return /^[\da-zA-Z]{8}$/.test(key) ? key : convert(key);
  });

export const decamelize = (obj, ignoreHashRegex = false) =>
  // 😨 When `ignoreHashRegex` is `true`, all keys for the `obj` will be decamelized.
  decamelizeKeys(obj, (key, convert) => {
    return !ignoreHashRegex && /^[\da-zA-Z]{8}$/.test(key) ? key : convert(key);
  });

import { decamelize } from 'helpers/camelize';

import { fetchWrapper, objectToQueryString, handleResponse, buildOpts, hcUrl } from '../helpers';

const getBottomSheet = async ({ params }) => {
  const { menuSlug, ...restParams } = params;

  const response = await fetchWrapper(
    hcUrl(
      `/user_incentives/bottom_sheet/${menuSlug}?${objectToQueryString(
        decamelize(restParams, true)
      )}`
    ),
    buildOpts()
  );
  return handleResponse(response);
};

export { getBottomSheet };

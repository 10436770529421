import {
  GET_MEAL_NOTE_RESPONSE,
  ADD_MEAL_NOTE_RESPONSE,
  UPDATE_MEAL_NOTE_RESPONSE,
} from 'actions/mealNotes';
const initialState = {};

export default (state = initialState, action) => {
  const { type, response } = action;

  switch (type) {
    case UPDATE_MEAL_NOTE_RESPONSE:
    case GET_MEAL_NOTE_RESPONSE:
    case ADD_MEAL_NOTE_RESPONSE:
      return {
        ...state,
        [response.mealNote.mealId]: response.mealNote,
      };
    default:
      return state;
  }
};

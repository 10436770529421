import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_MEAL_NOTE = 'GET_MEAL_NOTE';
export const GET_MEAL_NOTE_REQUEST = 'GET_MEAL_NOTE_REQUEST';
export const GET_MEAL_NOTE_RESPONSE = 'GET_MEAL_NOTE_RESPONSE';
export const GET_MEAL_NOTE_ERROR = 'GET_MEAL_NOTE_ERROR';

export const ADD_MEAL_NOTE = 'ADD_MEAL_NOTE';
export const ADD_MEAL_NOTE_REQUEST = 'ADD_MEAL_NOTE_REQUEST';
export const ADD_MEAL_NOTE_RESPONSE = 'ADD_MEAL_NOTE_RESPONSE';
export const ADD_MEAL_NOTE_ERROR = 'ADD_MEAL_NOTE_ERROR';

export const UPDATE_MEAL_NOTE = 'UPDATE_MEAL_NOTE';
export const UPDATE_MEAL_NOTE_REQUEST = 'UPDATE_MEAL_NOTE_REQUEST';
export const UPDATE_MEAL_NOTE_RESPONSE = 'UPDATE_MEAL_NOTE_RESPONSE';
export const UPDATE_MEAL_NOTE_ERROR = 'UPDATE_MEAL_NOTE_ERROR';

export const getMealNote = params =>
  makeApiRequest({
    requestType: GET_MEAL_NOTE,
    method: RequestService.getMealNote,
    params,
  });

export const addMealNote = params =>
  makeApiRequest({
    requestType: ADD_MEAL_NOTE,
    method: RequestService.addMealNote,
    params,
  });

export const updateMealNote = (mealNoteId, params) =>
  makeApiRequest({
    requestType: UPDATE_MEAL_NOTE,
    method: RequestService.updateMealNote,
    params: { mealNoteId, ...params },
  });

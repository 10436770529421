export const GET_PAST_RECEIPT = 'GET_PAST_RECEIPT';
export const GET_PAST_RECEIPT_REQUEST = 'GET_PAST_RECEIPT_REQUEST';
export const GET_PAST_RECEIPT_RESPONSE = 'GET_PAST_RECEIPT_RESPONSE';
export const GET_PAST_RECEIPT_ERROR = 'GET_PAST_RECEIPT_ERROR';

import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const getPastReceipt = menuSlug =>
  makeApiRequest({
    requestType: GET_PAST_RECEIPT,
    method: RequestService.getPastReceipt,
    params: { menuSlug: menuSlug },
  });

import { makeApiRequest } from 'actions/api';
import redirectToLogin from 'helpers/redirectToLogin';
import * as fromAccount from 'reducers/account';
import RequestService from 'services/RequestService';

export const GET_MEALS_REQUEST = 'GET_MEALS_REQUEST';
export const GET_MEALS_ERROR = 'GET_MEALS_ERROR';
export const GET_MEALS_RESPONSE = 'GET_MEALS_RESPONSE';

export const getMealsResponse = response => ({
  type: GET_MEALS_RESPONSE,
  response,
});

export const getMealsRequest = params => ({
  type: GET_MEALS_REQUEST,
  params,
});

export const getMealsError = error => ({ type: GET_MEALS_ERROR, error });

export function getMeals(params) {
  return (dispatch, _, { requestService }) => {
    dispatch(getMealsRequest(params));
    return requestService
      .getMealSelections({ params })
      .then(response => {
        if (response.status == 401) return redirectToLogin();
        return dispatch(getMealsResponse(response));
      })
      .catch(error => dispatch(getMealsError(error)));
  };
}

export const GET_FEATURED_MEALS_REQUEST = 'GET_FEATURED_MEALS_REQUEST';
export const GET_FEATURED_MEALS_ERROR = 'GET_FEATURED_MEALS_ERROR';
export const GET_FEATURED_MEALS_RESPONSE = 'GET_FEATURED_MEALS_RESPONSE';
export const GET_FEATURED_MEALS = 'GET_FEATURED_MEALS';

export const getFeaturedMeals = () =>
  makeApiRequest({
    requestType: GET_FEATURED_MEALS,
    method: RequestService.getFeaturedMeals,
  });

export const GET_PAUSE_DEFLECTOR_MEALS_REQUEST = 'GET_PAUSE_DEFLECTOR_MEALS_REQUEST';
export const GET_PAUSE_DEFLECTOR_MEALS_ERROR = 'GET_PAUSE_DEFLECTOR_MEALS_ERROR';
export const GET_PAUSE_DEFLECTOR_MEALS_RESPONSE = 'GET_PAUSE_DEFLECTOR_MEALS_RESPONSE';
export const GET_PAUSE_DEFLECTOR_MEALS = 'GET_PAUSE_DEFLECTOR_MEALS';

export const getPauseDeflectorMeals = () => (dispatch, getState) => {
  const state = getState();

  const pauseDeflectorMeals = fromAccount.selectPauseDeflectorMealsResults(state);
  const isPauseDeflectorMealsFetching = fromAccount.selectPauseDeflectorMealsFetching(state);

  if (pauseDeflectorMeals.length === 0 && !isPauseDeflectorMealsFetching) {
    dispatch(
      makeApiRequest({
        requestType: GET_PAUSE_DEFLECTOR_MEALS,
        method: RequestService.getPauseDeflectorMeals,
      })
    );
  }
};

export const GET_MEAL_REQUEST = 'GET_MEAL_REQUEST';
export const GET_MEAL_ERROR = 'GET_MEAL_ERROR';
export const GET_MEAL_RESPONSE = 'GET_MEAL_RESPONSE';
export const GET_MEAL = 'GET_MEAL';

export const getMeal = mealId =>
  makeApiRequest({
    requestType: GET_MEAL,
    method: RequestService.getMeal,
    params: { mealId },
  });

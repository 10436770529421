import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_SUGGESTED_PAIRINGS = 'GET_SUGGESTED_PAIRINGS';
export const GET_SUGGESTED_PAIRINGS_REQUEST = 'GET_SUGGESTED_PAIRINGS_REQUEST';
export const GET_SUGGESTED_PAIRINGS_RESPONSE = 'GET_SUGGESTED_PAIRINGS_RESPONSE';
export const GET_SUGGESTED_PAIRINGS_ERROR = 'GET_SUGGESTED_PAIRINGS_ERROR';

export const getSuggestedPairings = menuSlug =>
  makeApiRequest({
    requestType: GET_SUGGESTED_PAIRINGS,
    method: RequestService.getSuggestedPairings,
    params: { menuSlug: menuSlug },
  });

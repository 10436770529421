import { makeApiRequest } from 'actions/api';
import { accept } from 'actions/dismissals';
import { ADDRESS_REMINDER_KEY } from 'helpers/dismissals';
import RequestService from 'services/RequestService';

export const GET_REACTIVATION = 'GET_REACTIVATION';
export const GET_REACTIVATION_REQUEST = 'GET_REACTIVATION_REQUEST';
export const GET_REACTIVATION_RESPONSE = 'GET_REACTIVATION_RESPONSE';
export const GET_REACTIVATION_ERROR = 'GET_REACTIVATION_ERROR';

export const UPDATE_REACTIVATION = 'UPDATE_REACTIVATION';
export const UPDATE_REACTIVATION_REQUEST = 'UPDATE_REACTIVATION_REQUEST';
export const UPDATE_REACTIVATION_RESPONSE = 'UPDATE_REACTIVATION_RESPONSE';
export const UPDATE_REACTIVATION_ERROR = 'UPDATE_REACTIVATION_ERROR';

export const getRestartCampaign = () =>
  makeApiRequest({ requestType: GET_REACTIVATION, method: RequestService.getRestartCampaign });

export const updateRestartCampaign = params => {
  return async dispatch => {
    const response = await dispatch(
      makeApiRequest({
        requestType: UPDATE_REACTIVATION,
        method: RequestService.updateRestartCampaign,
        params,
      })
    );
    if (response.ok) {
      dispatch(accept(ADDRESS_REMINDER_KEY));
    }
    return response;
  };
};

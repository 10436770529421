import {
  fetchWrapper,
  handleResponse,
  formatBody,
  hcUrl,
  buildOpts,
} from 'services/RequestService/helpers';

const addOptInSelection = async ({ params: { optInId } }) => {
  const response = await fetchWrapper(
    hcUrl('/opt_in_selections'),
    buildOpts({
      method: 'POST',
      body: formatBody({
        OptInSelection: {
          OptInId: optInId,
        },
      }),
    })
  );
  return handleResponse(response);
};

const updateOptInSelections = async ({ params: { optInIds } }) => {
  const response = await fetchWrapper(
    hcUrl('/opt_in_selections/batch_update'),
    buildOpts({
      method: 'PATCH',
      body: formatBody({ optInSelections: { optInIds } }, true),
    })
  );
  return handleResponse(response);
};

export { addOptInSelection, updateOptInSelections };

import {
  fetchWrapper,
  handleResponse,
  formatBody,
  hcUrl,
  buildOpts,
} from 'services/RequestService/helpers';

const addMealAddonSelection = async ({ params: { mealAddonId } }) => {
  const response = await fetchWrapper(
    hcUrl('/meal_addon_selections'),
    buildOpts({
      method: 'POST',
      body: formatBody({
        mealAddonSelection: {
          mealAddonId: mealAddonId,
        },
      }),
    })
  );
  return handleResponse(response);
};

const updateMealAddonSelections = async ({ params: { mealAddonIds } }) => {
  const response = await fetchWrapper(
    hcUrl('/meal_addon_selections/batch_update'),
    buildOpts({
      method: 'PATCH',
      body: formatBody({ mealAddonSelections: { mealAddonIds: mealAddonIds } }),
    })
  );
  return handleResponse(response);
};

export { addMealAddonSelection, updateMealAddonSelections };

import {
  fetchWrapper,
  handleResponse,
  formatBody,
  hcUrl,
  buildOpts,
} from 'services/RequestService/helpers';

const idMeVerification = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/id_me_verify'),
    buildOpts({
      method: 'POST',
      body: formatBody({ token: params.token }),
    })
  );
  return handleResponse(response);
};

export { idMeVerification };

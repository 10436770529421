export const backupMenuCategories = [
  {
    color: '#008600',
    description:
      'These classic meal kits arrive with pre-portioned ingredients and simple to follow instructions for dinner made easy.',
    displayOrder: 1,
    slug: 'meal-kits',
    slugs: [
      'meal-kit',
      'culinary-collection',
      'holiday',
      'family-meal',
      'one-pot-one-pan',
      'meal-kits',
      'slider-burger-kit',
      'pizza-night',
    ],
    title: 'Meal Kits',
  },
  {
    color: '#b81c2d',
    description:
      'Simple-to-follow recipes with easy prep that come together in less than 30 minutes.',
    displayOrder: 2,
    slug: 'express',
    slugs: ['express', 'express-plus', 'express-family'],
    title: 'Express',
  },
  {
    color: '#2a7de1',
    description:
      'These kits require little prep and arrive to your door with fresh, pre-chopped ingredients in an oven-safe tin.',
    displayOrder: 3,
    slug: 'oven-ready',
    slugs: ['oven-ready', 'oven-ready-plus', 'oven-ready-family'],
    title: 'Oven-Ready',
  },
  {
    color: '#00809c',
    description:
      'Fast & Fresh meals assemble quickly and heat in the microwave in minutes. Save time and minimize cleanup without sacrificing flavor!',
    displayOrder: 4,
    slug: 'fast-fresh',
    slugs: ['fast-fresh', 'fast-fresh-plus', 'fast-fresh-family'],
    title: 'Fast & Fresh',
  },
  {
    color: '#f07b00',
    description:
      'Skip the leftovers and the long lines this week! Our Lunch options make midday meals quick, easy, and delicious.',
    displayOrder: 5,
    slug: 'lunch',
    slugs: ['lunch', 'rth-placeholder-single-serve'],
    title: 'Lunch',
  },
  {
    color: '#f07b00',
    description:
      'Looking for a delicious bundle of some of our most popular Extras? Look no further!',
    displayOrder: 6,
    slug: 'bundle-save',
    slugs: ['bundle'],
    title: 'Bundle & Save',
  },
  {
    color: '#f07b00',
    description: '',
    displayOrder: 7,
    slug: 'extras',
    slugs: [
      'appetizers',
      'beverages',
      'bread',
      'breakfast',
      'dessert',
      'dips',
      'mac-cheese',
      'mini-kits',
      'protein',
      'protein-pack',
      'salad',
      'sides',
      'smoothie',
      'soup',
      'pantry',
      'snack',
      'pizza',
    ],
    title: 'Extras',
  },
];

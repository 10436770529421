import get from 'lodash/get';
import { useState, createContext, useEffect } from 'react';

import { dashboardRootPath } from 'helpers/routes';

const BrandContext = createContext({});

const HOME_CHEF = 'homechef';
const TEMPO = 'tempo';

const getBrandFor = rootElement => {
  return get(rootElement, 'dataset.brand', HOME_CHEF);
};

const getCoreUrl = rootElement => {
  return get(rootElement, 'dataset.hcDomain', 'https://www.homechef.com');
};

const getPostSignupUrl = ({ brand, coreUrl }) => {
  return brand === HOME_CHEF ? dashboardRootPath() : `${coreUrl}${dashboardRootPath()}`;
};

/**
 * This context can be applied to any React app that requires design changes
 * dependent on the current domain (i.e., homechef.com vs tempomeals.com).
 *
 * This component should wrap the entirety of the app so that all child components
 * have access to the the provided `brand` prop.
 *
 * @param {Element} rootElement - The root HTML element of the containing React app, e.g., document.getElementById('hc-sign-up-funnel-react-app')
 * @param {ReactNode} children - The child component(s)
 * @returns A React component wrapped with prop `brand`
 */

const BrandContextProvider = ({ rootElement, children }) => {
  const [brand, setBrand] = useState(null);
  const coreUrl = getCoreUrl(rootElement);

  useEffect(() => {
    setBrand(getBrandFor(rootElement));
  }, []);

  return (
    <BrandContext.Provider
      value={{
        brand,
        setBrand,
        postSignupUrl: getPostSignupUrl({ brand, coreUrl }),
        coreUrl,
      }}
    >
      {children}
    </BrandContext.Provider>
  );
};

BrandContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  rootElement: PropTypes.object.isRequired,
};

export { BrandContext, BrandContextProvider, HOME_CHEF, TEMPO, getPostSignupUrl };

import {
  fetchWrapper,
  handleResponse,
  hcUrl,
  buildOpts,
  transformShoppingListMealResponse,
} from 'services/RequestService/helpers';

export const getShoppingListMeal = async ({ params: { mealId } }) => {
  const response = await fetchWrapper(
    hcUrl(`/shopping_list_meals/${mealId}`),
    buildOpts({
      method: 'GET',
    })
  );
  return handleResponse(response, transformShoppingListMealResponse);
};

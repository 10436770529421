import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_MENU = 'GET_MENU';
export const GET_MENU_REQUEST = 'GET_MENU_REQUEST';
export const GET_MENU_RESPONSE = 'GET_MENU_RESPONSE';
export const GET_MENU_ERROR = 'GET_MENU_ERROR';

export const getMenu = menuSlug =>
  makeApiRequest({
    requestType: GET_MENU,
    method: RequestService.getMenu,
    params: { menuSlug },
  });

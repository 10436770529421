import { STANDARD, FAMILY, TEMPO } from 'lib/constants';

export const displayServingsText = ({ displayUnit, servings }) => {
  const adjustedServings = parseInt(servingsMultiplier(displayUnit.increment) * servings);
  return `${adjustedServings} ${
    adjustedServings === 1 ? displayUnit.singular : displayUnit.plural
  }`;
};

export const displayFunnelServingsText = ({ servings, increment }) => {
  return displayServingsText({
    servings,
    displayUnit: { increment, singular: '', plural: '' },
  });
};

export const displayMealText = num => (num === 1 ? 'meal' : 'meals');

export const displayPrice = ({ displayUnit, price }) =>
  priceMultiplier(displayUnit.increment) * price;

const servingsMultiplier = increment => Number(increment) / 2;
const priceMultiplier = increment => 2 / Number(increment);

export const projectedServings = {
  [STANDARD]: {
    2: 2,
    4: 2,
    6: 4,
    8: 4,
  },
  [FAMILY]: {
    2: 4,
    4: 8,
  },
};

export const subscriptionFormServingSizeMap = {
  [STANDARD]: {
    4: 6,
    2: 4,
  },
  [FAMILY]: {
    6: 4,
    4: 2,
    2: 2,
  },
};

const convertSlug = mealPlanSlug => {
  if (mealPlanSlug === STANDARD) return 'core';
  return mealPlanSlug;
};

const mealPlanTransition = (originalMealPlanSlug, currentMealPlanSlug) => {
  return `${convertSlug(originalMealPlanSlug)}-${convertSlug(currentMealPlanSlug)}`;
};

export const getServingSize = (originalMealPlanSlug, currentMealPlanSlug, servings) => {
  if (currentMealPlanSlug === TEMPO) return 2;

  const servingSizeMap = {
    'core-family': subscriptionFormServingSizeMap.family[servings],
    'core-core': servings,
    'family-core': subscriptionFormServingSizeMap.standard[servings],
    'tempo-family': 2,
    'tempo-core': 2,
  };

  const key = mealPlanTransition(originalMealPlanSlug, currentMealPlanSlug);
  return servingSizeMap[key] || servings;
};

export const getWeeklyMeals = (originalMealPlanSlug, currentMealPlanSlug, weeklyMeals) => {
  if (currentMealPlanSlug === TEMPO) return 6;

  const weeklyMealsMap = {
    'core-family': weeklyMeals,
    'core-core': weeklyMeals,
    'family-core': weeklyMeals,
    'tempo-family': 2,
    'tempo-core': 3,
  };

  const key = mealPlanTransition(originalMealPlanSlug, currentMealPlanSlug);
  return weeklyMealsMap[key] || weeklyMeals;
};

export const servingsDisplay = box => {
  if (!box?.pricePerServingLowFormatted || !box?.pricePerServingHighFormatted)
    return box?.pricePerServingFormatted;
  return `${box?.pricePerServingLowFormatted} - ${box?.pricePerServingHighFormatted}`;
};

export const servingsDisplayWithPromo = box => {
  return box?.promo.promoPricePerServingRange || box?.promo.pricePerServingFormatted;
};

export const hasServingRange = box => {
  return !!(box?.pricePerServingLow && box?.pricePerServingHigh);
};

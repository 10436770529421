import {
  fetchWrapper,
  handleResponse,
  hcUrl,
  buildOpts,
  transformMealResponse,
} from 'services/RequestService/helpers';

export const getNutritionalFacts = async ({ params: { mealId } }) => {
  const response = await fetchWrapper(hcUrl(`/nutritional_facts/${mealId}`), buildOpts());
  return handleResponse(response, transformMealResponse);
};

import {
  fetchWrapper,
  handleResponse,
  formatBody,
  hcUrl,
  buildOpts,
  transformUserResponse,
} from 'services/RequestService/helpers';

const getUser = async ({ params: { detailLevel } }) => {
  const response = await fetchWrapper(hcUrl(`/user?detail_level=${detailLevel}`), buildOpts());

  return handleResponse(response, transformUserResponse);
};

const updateUser = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/user'),
    buildOpts({
      method: 'PATCH',
      body: formatBody({ user: params }),
    })
  );
  return handleResponse(response);
};

const updatePayment = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/user/billing'),
    buildOpts({
      method: 'PATCH',
      body: formatBody(params),
    })
  );
  return handleResponse(response);
};

const updateFailedPayment = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/user/failed_payments'),
    buildOpts({
      method: 'PATCH',
      body: formatBody(params),
    })
  );
  return handleResponse(response);
};

const getPaypalToken = async () => {
  const response = await fetchWrapper('/billing/braintree/client_token');
  return handleResponse(response);
};

const updatePassword = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/user/password'),
    buildOpts({
      method: 'PATCH',
      body: formatBody(params),
    })
  );
  return handleResponse(response);
};

const unpause = async ({ params = {} }) => {
  const response = await fetchWrapper(
    hcUrl('/user/pause'),
    buildOpts({
      method: 'DELETE',
      body: formatBody(params),
    })
  );
  return handleResponse(response);
};

const pause = async ({ params: { reason } }) => {
  const response = await fetchWrapper(
    hcUrl('/user/pause'),
    buildOpts({
      method: 'POST',
      body: formatBody({ pause: { reason } }),
    })
  );
  return handleResponse(response);
};

const getTasteProfile = async () => {
  const response = await fetchWrapper(hcUrl('/user/taste_profiles'));
  return handleResponse(response);
};

const updateTasteProfile = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/user/taste_profiles'),
    buildOpts({
      method: 'PUT',
      body: formatBody({ tasteProfiles: params }),
    })
  );
  return handleResponse(response);
};

const sendInvitation = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/user/invitations'),
    buildOpts({
      method: 'POST',
      body: formatBody({ invitation: params }),
    })
  );
  return handleResponse(response);
};

const shareRecipe = async ({ params }) => {
  const { mealId, ...restParams } = params;
  const response = await fetchWrapper(
    hcUrl(`/user/share_recipes/${mealId}`),
    buildOpts({
      method: 'POST',
      body: formatBody({ shareRecipe: restParams }),
    })
  );
  return handleResponse(response);
};

const updateEmailSettings = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/user/email_settings'),
    buildOpts({
      method: 'PUT',
      body: formatBody({ emailSettings: params }),
    })
  );
  return handleResponse(response);
};

const updateNotificationPreferences = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/user/notification_preferences'),
    buildOpts({
      method: 'PUT',
      body: formatBody({ notificationPreferences: params }),
    })
  );
  return handleResponse(response);
};

const updateStatus = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/user/status'),
    buildOpts({
      method: 'PATCH',
      body: formatBody({ user: params }),
    })
  );
  return handleResponse(response);
};

const updateAccountVerification = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/user/account_verification'),
    buildOpts({
      method: 'PATCH',
      body: formatBody({ user: params }),
    })
  );
  return handleResponse(response);
};

const redeemPauseCredit = async () => {
  const response = await fetchWrapper(
    hcUrl('/user/credits'),
    buildOpts({
      method: 'POST',
    })
  );
  return handleResponse(response);
};

const logoutUser = async () => {
  const response = await fetchWrapper(
    '/users/sign_out',
    buildOpts({
      method: 'DELETE',
    })
  );
  return handleResponse(response);
};

export {
  getUser,
  sendInvitation,
  shareRecipe,
  updateUser,
  unpause,
  pause,
  getTasteProfile,
  updateTasteProfile,
  updatePassword,
  updatePayment,
  updateFailedPayment,
  getPaypalToken,
  updateEmailSettings,
  updateNotificationPreferences,
  updateStatus,
  updateAccountVerification,
  redeemPauseCredit,
  logoutUser,
};

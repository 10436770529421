import omit from 'lodash/omit';

import {
  REMOVE_FAVORITE_REQUEST,
  GET_FAVORITES_RESPONSE,
  ADD_FAVORITE_RESPONSE,
} from 'actions/favorites';

const defaultState = {};

export default (state = defaultState, action) => {
  const { type, response, params } = action;

  switch (type) {
    case REMOVE_FAVORITE_REQUEST:
      return omit(state, params.mealConcept);
    case GET_FAVORITES_RESPONSE:
      return { ...state, ...response.favorites };
    case ADD_FAVORITE_RESPONSE:
      return { ...state, ...response.favorite };
    default:
      return state;
  }
};

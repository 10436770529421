import moment from 'moment/moment';

import { ONBOARDING_MODAL_KEY } from 'helpers/dismissals';
import * as fromDismissals from 'reducers/dismissals';

export const ACCEPT = 'ACCEPT';
export const DISMISS = 'DISMISS';

export const accept = key => ({ type: ACCEPT, key });
export const dismiss = key => ({ type: DISMISS, key });

export const checkOnboardingAndDismissIfStale = user => (dispatch, getState) => {
  const completedSignupAt = moment(user.completedSignupAt);
  const onboardingDismissed = fromDismissals.selectIsDismissed(getState(), ONBOARDING_MODAL_KEY);
  const oneMonthAgo = moment(new Date()).subtract(1, 'months');

  if (completedSignupAt.isBefore(oneMonthAgo) && !onboardingDismissed) {
    dispatch(dismiss(ONBOARDING_MODAL_KEY));
  }
};

import classNames from 'classnames';

const FiberRich = ({ className }) => {
  return (
    <svg
      viewBox="0 0 17 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('dietary-tag', className)}
      role="presentation"
      style={{ color: 'transparent' }}
      pointerEvents="none"
    >
      <title>Fiber-Rich</title>
      <g clipPath="url(#clip0_200_10118)">
        <path
          d="M12.0763 6.43831C11.3624 7.1228 10.4479 7.10719 9.70982 7.09555C9.14301 7.08685 8.65301 7.07914 8.36982 7.35067C8.08663 7.62219 8.07373 8.11208 8.05859 8.67875C8.03894 9.4139 8.01633 10.331 7.30244 11.0155C6.19519 12.0771 4.39302 11.9337 3.19933 10.6887C1.29861 8.70629 1.70867 5.21747 4.11362 2.91159C6.51858 0.605715 10.0216 0.342705 11.9223 2.32509C13.116 3.57007 13.1832 5.37698 12.0763 6.43831Z"
          fill="#34A86E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.7097 7.10109L9.70602 7.10106L9.70485 7.10104C9.13854 7.09235 8.64902 7.08484 8.36602 7.35618C8.08302 7.62752 8.06994 8.11693 8.05482 8.68311L8.05463 8.69026C8.03501 9.42439 8.01059 10.3383 7.29864 11.021C6.19139 12.0826 4.38922 11.9392 3.19553 10.6942C1.29481 8.7118 1.70486 5.22298 4.10982 2.9171C6.51478 0.611224 10.0178 0.348214 11.9185 2.3306C13.1122 3.57558 13.1794 5.38249 12.0725 6.44382C11.3598 7.12715 10.4471 7.11272 9.7097 7.10109ZM7.28018 9.12784C7.2481 9.09843 7.21676 9.06779 7.18621 9.03593C6.41636 8.23301 6.44318 6.95802 7.2461 6.18818C8.04903 5.41833 9.32401 5.44514 10.0939 6.24807C10.1244 6.27998 10.1538 6.31263 10.1819 6.34597C10.7385 6.33201 11.1858 6.24758 11.5497 5.89864C12.2973 5.18182 12.3375 3.85894 11.3733 2.85333C9.85119 1.26583 6.83966 1.3461 4.63254 3.46229C2.42543 5.57848 2.21861 8.58396 3.74071 10.1715C4.70495 11.1771 6.02804 11.1929 6.77591 10.4758C7.13957 10.1271 7.24269 9.6826 7.28018 9.12784Z"
          fill="#2B2B2B"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.82502 12.1995C8.00049 12.1627 8.15036 12.0984 8.26581 11.9876C8.54868 11.7161 8.56151 11.2267 8.57636 10.6605L8.57655 10.6533C8.58362 10.3838 8.59132 10.09 8.63346 9.79232V9.79232C8.70609 9.27917 8.88118 8.75446 9.33155 8.32222C10.4383 7.26004 12.2405 7.40259 13.4348 8.64698C15.3365 10.6284 14.9282 14.1175 12.5243 16.4245C10.1205 18.7316 6.61767 18.9963 4.71598 17.0148C3.52168 15.7705 3.45357 13.9636 4.55998 12.9017C5.27233 12.218 6.18494 12.232 6.92235 12.2432L6.92726 12.2433C7.26237 12.2483 7.57059 12.2529 7.82502 12.1995ZM9.35093 10.2155C9.38294 10.2449 9.41423 10.2754 9.44473 10.3072C10.215 11.1098 10.1888 12.3847 9.38624 13.155C8.58369 13.9252 7.30869 13.899 6.53845 13.0965C6.50784 13.0646 6.4785 13.032 6.4504 12.9986C5.89391 13.0129 5.44669 13.0976 5.08297 13.4466C4.33571 14.1638 4.29623 15.4867 5.26091 16.4919C6.78379 18.0786 9.79528 17.9969 12.0014 15.8796C14.2074 13.7623 14.4128 10.7567 12.8899 9.16997C11.9252 8.16478 10.6021 8.14971 9.85454 8.86714C9.49101 9.21604 9.38813 9.66066 9.35093 10.2155Z"
          fill="#2B2B2B"
        />
      </g>
    </svg>
  );
};

FiberRich.propTypes = {
  className: PropTypes.string,
};

export default FiberRich;

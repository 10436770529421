import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_BOTTOM_SHEET = 'GET_BOTTOM_SHEET';
export const GET_BOTTOM_SHEET_REQUEST = 'GET_BOTTOM_SHEET_REQUEST';
export const GET_BOTTOM_SHEET_RESPONSE = 'GET_BOTTOM_SHEET_RESPONSE';
export const GET_BOTTOM_SHEET_ERROR = 'GET_BOTTOM_SHEET_ERROR';

export const getBottomSheet = (menuSlug, params) =>
  makeApiRequest({
    requestType: GET_BOTTOM_SHEET,
    method: RequestService.getBottomSheet,
    params: { menuSlug, ...params },
  });

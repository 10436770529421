import { decamelize } from 'helpers/camelize';
import {
  fetchWrapper,
  handleResponse,
  hcUrl,
  buildOpts,
  formatBody,
  objectToQueryString,
} from 'services/RequestService/helpers';

const getMessagingKey = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl(`/dismissible_messaging?${objectToQueryString(decamelize(params))}`),
    buildOpts()
  );
  return handleResponse(response);
};

const setMessagingKey = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/dismissible_messaging'),
    buildOpts({
      method: 'POST',
      body: formatBody(params),
    })
  );
  return handleResponse(response);
};

export { getMessagingKey, setMessagingKey };

import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_MENU_CATEGORIES = 'GET_MENU_CATEGORIES';
export const GET_MENU_CATEGORIES_REQUEST = 'GET_MENU_CATEGORIES_REQUEST';
export const GET_MENU_CATEGORIES_RESPONSE = 'GET_MENU_CATEGORIES_RESPONSE';
export const GET_MENU_CATEGORIES_ERROR = 'GET_MENU_CATEGORIES_ERROR';

export const getMenuCategories = menuSlug =>
  makeApiRequest({
    requestType: GET_MENU_CATEGORIES,
    method: RequestService.getMenuCategories,
    params: { menuSlug },
  });

import { makeApiRequest } from 'actions/api';
import * as fromDashboard from 'reducers/dashboard';
import RequestService from 'services/RequestService';

export const GET_BEHAVIORAL_DISCOUNTS = 'GET_BEHAVIORAL_DISCOUNTS';
export const GET_BEHAVIORAL_DISCOUNTS_REQUEST = 'GET_BEHAVIORAL_DISCOUNTS_REQUEST';
export const GET_BEHAVIORAL_DISCOUNTS_RESPONSE = 'GET_BEHAVIORAL_DISCOUNTS_RESPONSE';
export const GET_BEHAVIORAL_DISCOUNTS_ERROR = 'GET_BEHAVIORAL_DISCOUNTS_ERROR';

export const getBehavioralDiscounts = menuSlug =>
  makeApiRequest({
    requestType: GET_BEHAVIORAL_DISCOUNTS,
    method: RequestService.getBehavioralDiscounts,
    params: { menuSlug },
  });

export const refreshBehavioralDiscounts = menuSlug => async (dispatch, getState) => {
  const state = getState();
  const menuSlugs = fromDashboard.selectMenuSlugs(state);
  const menusToFetch = menuSlugs.slice(menuSlugs.indexOf(menuSlug));

  return await Promise.all(
    menusToFetch.map(menuSlug => dispatch(getBehavioralDiscounts(menuSlug)))
  );
};

import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_QUICK_FILTERS = 'GET_QUICK_FILTERS';
export const GET_QUICK_FILTERS_REQUEST = 'GET_QUICK_FILTERS_REQUEST';
export const GET_QUICK_FILTERS_RESPONSE = 'GET_QUICK_FILTERS_RESPONSE';
export const GET_QUICK_FILTERS_ERROR = 'GET_QUICK_FILTERS_ERROR';

export const getQuickFilters = menuSlug =>
  makeApiRequest({
    requestType: GET_QUICK_FILTERS,
    method: RequestService.getQuickFilters,
    params: { menuSlug },
  });

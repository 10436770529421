import {
  fetchWrapper,
  handleResponse,
  hcUrl,
  buildOpts,
  transformMealResponse,
  transformMealsResponse,
} from 'services/RequestService/helpers';

export const getFeaturedMeals = async () => {
  const response = await fetchWrapper(hcUrl('/meals/favorites'), buildOpts());

  return handleResponse(response, transformMealsResponse);
};

export const getPauseDeflectorMeals = async () => {
  const response = await fetchWrapper(hcUrl('/liked_meals'), buildOpts());

  return handleResponse(response);
};

export const getMeal = async ({ params: { mealId } }) => {
  const response = await fetchWrapper(hcUrl(`/meals/${mealId}`), buildOpts());

  return handleResponse(response, transformMealResponse);
};

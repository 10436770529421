/* global localStorage */
import { camelize } from 'humps';

import LocalStorage from 'helpers/localStorage';

export const ADDRESS_REMINDER_KEY = 'updateAddressReminder';
export const REFERRAL_INTERSTITIAL_KEY = 'referralInterstitial';
export const ONBOARDING_MODAL_KEY = 'onboardingModal';
export const DOWNLOAD_MOBILE_INTERSTITIAL_KEY = 'downloadMobileInterstitial';
export const MAINTENANCE_BANNER_KEY = 'maintenance2023Banner';

export const optInDismissalKey = id => `opt_in_${id}`;
export const addonDismissalKey = addon => `addon_${addon.id}`;
export const promotionMessageKey = promotionMessage =>
  camelize(`promotion_${promotionMessage.heading}`);

export const migrateDismissals = () => {
  if (!LocalStorage.isAvailable()) {
    return {};
  }

  const migrationMap = {
    HomeChefSession_modal_onboarding: ONBOARDING_MODAL_KEY,
    HomeChefSession_onboarding_modal: ONBOARDING_MODAL_KEY,
    HomeChefSession_interstitial_referral: REFERRAL_INTERSTITIAL_KEY,
    HomeChefSession_maintenance_banner: MAINTENANCE_BANNER_KEY,
  };

  const migratedData = {};

  Object.keys(localStorage).forEach(key => {
    if (migrationMap[key]) {
      migratedData[migrationMap[key]] = true;
      localStorage.removeItem(key);
    } else if (key.startsWith('HomeChefSession_takeover_addon')) {
      const newKey = key.split('HomeChefSession_takeover_')[1];
      migratedData[newKey] = true;
      localStorage.removeItem(key);
    } else if (key.startsWith('HomeChefSession_promotion')) {
      const newKey = camelize(key.split('HomeChefSession_')[1]);
      migratedData[newKey] = true;
      localStorage.removeItem(key);
    }
  });

  return migratedData;
};

import { API_UNAUTHORIZED_ERROR } from 'actions/api';
import {
  BOOTSTRAP_DASHBOARD,
  BOOTSTRAP_DASHBOARD_ERROR,
  BOOTSTRAP_DASHBOARD_SUCCESS,
} from 'actions/dashboard';

const initialState = {
  initializationComplete: false,
  isUnauthorized: false,
  needsInitializing: true,
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case BOOTSTRAP_DASHBOARD:
      return { ...state, needsInitializing: false };
    case BOOTSTRAP_DASHBOARD_ERROR:
      return { ...state, needsInitializing: true };
    case BOOTSTRAP_DASHBOARD_SUCCESS:
      return { ...state, initializationComplete: true };
    case API_UNAUTHORIZED_ERROR:
      return { ...state, isUnauthorized: true };
    default:
      return state;
  }
};

export const isInitializationComplete = state => state.initializationComplete;
export const needsInitializing = state => state.needsInitializing;
export const isUnauthorized = state => state.isUnauthorized;

import { GET_MEALS_REQUEST, GET_MEALS_ERROR, GET_MEALS_RESPONSE } from 'actions/meals';

const startState = {
  isLoaded: false,
  isLoading: false,
  isError: false,
};

export default (state = startState, action) => {
  const { type } = action;

  switch (type) {
    case GET_MEALS_REQUEST:
      return {
        ...state,
        isError: false,
        isLoading: true,
      };
    case GET_MEALS_RESPONSE:
      return {
        ...state,
        isError: false,
        isLoading: false,
        isLoaded: true,
      };
    case GET_MEALS_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

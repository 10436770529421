export const CREDIT_CARD = 'credit card';
export const BRAINTREE = 'braintree';
export const STRIPE_GOOGLE = 'stripe_google';
export const PAYPAL = 'paypal';
export const STRIPE = 'stripe';
export const STRIPE_APPLE = 'stripe_apple';
export const VISA = 'Visa';
export const MASTERCARD = 'MasterCard';
export const AMERICAN_EXPRESS = 'American Express';
export const DISCOVER = 'Discover';
// prettier-ignore
export const BILLING_AGREEMENT =
	'Home Chef\'s weekly meal service delivers everything you need to prepare home-cooked meals in about 30 minutes. Please enter your credit card and billing zip code below.';

import { SET_SCROLL_POSITION } from 'actions/scroll';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SCROLL_POSITION:
      return { ...state, [action.route]: action.value };
    default:
      return state;
  }
};

export const selectScrollPositionForRoute = (state, route) => state[route];

import { makeApiRequest } from 'actions/api';
import { refreshBaskets } from 'actions/baskets';
import { accept } from 'actions/dismissals';
import { getMessages } from 'actions/messageCenter';
import { ADDRESS_REMINDER_KEY } from 'helpers/dismissals';
import RequestService from 'services/RequestService';

export const ADD_MEAL_ADDON_SELECTION = 'ADD_MEAL_ADDON_SELECTION';
export const ADD_MEAL_ADDON_SELECTION_REQUEST = 'ADD_MEAL_ADDON_SELECTION_REQUEST';
export const ADD_MEAL_ADDON_SELECTION_RESPONSE = 'ADD_MEAL_ADDON_SELECTION_RESPONSE';
export const ADD_MEAL_ADDON_SELECTION_ERROR = 'ADD_MEAL_ADDON_SELECTION_ERROR';

export const addMealAddonSelection = mealAddonId =>
  makeApiRequest({
    requestType: ADD_MEAL_ADDON_SELECTION,
    method: RequestService.addMealAddonSelection,
    params: { mealAddonId },
  });

export const ADD_OPT_IN_SELECTION = 'ADD_OPT_IN_SELECTION';
export const ADD_OPT_IN_SELECTION_REQUEST = 'ADD_OPT_IN_SELECTION_REQUEST';
export const ADD_OPT_IN_SELECTION_RESPONSE = 'ADD_OPT_IN_SELECTION_RESPONSE';
export const ADD_OPT_IN_SELECTION_ERROR = 'ADD_OPT_IN_SELECTION_ERROR';

export const addOptInSelection = optInId =>
  makeApiRequest({
    requestType: ADD_OPT_IN_SELECTION,
    method: RequestService.addOptInSelection,
    params: { optInId },
  });

export const GET_USER = 'GET_USER';
export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_RESPONSE = 'GET_USER_RESPONSE';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const getUser = () =>
  makeApiRequest({
    requestType: GET_USER,
    method: RequestService.getUser,
    params: { detailLevel: 'summary' },
  });

export const getDetailedUser = () =>
  makeApiRequest({
    requestType: GET_USER,
    method: RequestService.getUser,
    params: { detailLevel: 'detailed' },
  });

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_RESPONSE = 'UPDATE_USER_RESPONSE';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const updateUser = params =>
  makeApiRequest({
    requestType: UPDATE_USER,
    method: RequestService.updateUser,
    params,
  });

export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION_REQUEST = 'UPDATE_SUBSCRIPTION_REQUEST';
export const UPDATE_SUBSCRIPTION_RESPONSE = 'UPDATE_SUBSCRIPTION_RESPONSE';
export const UPDATE_SUBSCRIPTION_ERROR = 'UPDATE_SUBSCRIPTION_ERROR';

export const updateSubscriptionInfo = params =>
  makeApiRequest({
    requestType: UPDATE_SUBSCRIPTION,
    method: RequestService.updateUser,
    metadata: {
      delivery_day: params.deliveryDay,
      frequency_subscription: params.frequencySubscription,
      meal_plan: params.mealPlanSlug,
      servings: params.servings,
      number_of_meals: params.weeklyMeals,
    },
    params,
  });

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST';
export const UPDATE_PASSWORD_RESPONSE = 'UPDATE_PASSWORD_RESPONSE';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';

export const updatePassword = params =>
  makeApiRequest({
    requestType: UPDATE_PASSWORD,
    method: RequestService.updatePassword,
    params,
  });

export const UNPAUSE_USER = 'UNPAUSE_USER';
export const UNPAUSE_USER_REQUEST = 'UNPAUSE_USER_REQUEST';
export const UNPAUSE_USER_RESPONSE = 'UNPAUSE_USER_RESPONSE';
export const UNPAUSE_USER_ERROR = 'UNPAUSE_USER_ERROR';

export const unpause = (cta = '', params = {}) => {
  return async dispatch => {
    const response = await dispatch(
      makeApiRequest({
        requestType: UNPAUSE_USER,
        method: RequestService.unpause,
        metadata: { cta },
        params,
      })
    );
    if (response.ok) {
      dispatch(getMessages());
      dispatch(refreshBaskets());
      dispatch(accept(ADDRESS_REMINDER_KEY));
    }
    return response;
  };
};

export const PAUSE_USER = 'PAUSE_USER';
export const PAUSE_USER_REQUEST = 'PAUSE_USER_REQUEST';
export const PAUSE_USER_RESPONSE = 'PAUSE_USER_RESPONSE';
export const PAUSE_USER_ERROR = 'PAUSE_USER_ERROR';

export const pause = reason => {
  return async dispatch => {
    const response = await dispatch(
      makeApiRequest({
        requestType: PAUSE_USER,
        method: RequestService.pause,
        params: { reason },
      })
    );
    if (!response.ok) {
      dispatch(getUser());
    }

    return response;
  };
};

export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const UPDATE_PAYMENT_REQUEST = 'UPDATE_PAYMENT_REQUEST';
export const UPDATE_PAYMENT_RESPONSE = 'UPDATE_PAYMENT_RESPONSE';
export const UPDATE_PAYMENT_ERROR = 'UPDATE_PAYMENT_ERROR';

export const updatePayment = params =>
  makeApiRequest({
    requestType: UPDATE_PAYMENT,
    method: RequestService.updatePayment,
    params: { billing: params },
  });

export const UPDATE_FAILED_PAYMENT = 'UPDATE_FAILED_PAYMENT';
export const UPDATE_FAILED_PAYMENT_REQUEST = 'UPDATE_FAILED_PAYMENT_REQUEST';
export const UPDATE_FAILED_PAYMENT_RESPONSE = 'UPDATE_FAILED_PAYMENT_RESPONSE';
export const UPDATE_FAILED_PAYMENT_ERROR = 'UPDATE_FAILED_PAYMENT_ERROR';

export const updateFailedPayment = params =>
  makeApiRequest({
    requestType: UPDATE_FAILED_PAYMENT,
    method: RequestService.updateFailedPayment,
    params: { billing: params },
  });

export const GET_PAYPAL_TOKEN = 'GET_PAYPAL_TOKEN';
export const GET_PAYPAL_TOKEN_REQUEST = 'GET_PAYPAL_TOKEN_REQUEST';
export const GET_PAYPAL_TOKEN_ERROR = 'GET_PAYPAL_TOKEN_ERROR';

export const getPaypalToken = () =>
  makeApiRequest({
    requestType: GET_PAYPAL_TOKEN,
    method: RequestService.getPaypalToken,
  });

export const SEND_INVITATION = 'SEND_INVITATION';
export const SEND_INVITATION_REQUEST = 'SEND_INVITATION_REQUEST';
export const SEND_INVITATION_RESPONSE = 'SEND_INVITATION_RESPONSE';
export const SEND_INVITATION_ERROR = 'SEND_INVITATION_ERROR';

export const sendInvitation = params =>
  makeApiRequest({
    requestType: SEND_INVITATION,
    method: RequestService.sendInvitation,
    params,
  });

export const UPDATE_MEAL_ADDON_SELECTIONS = 'UPDATE_MEAL_ADDON_SELECTIONS';
export const UPDATE_MEAL_ADDON_SELECTIONS_REQUEST = 'UPDATE_MEAL_ADDON_SELECTIONS_REQUEST';
export const UPDATE_MEAL_ADDON_SELECTIONS_RESPONSE = 'UPDATE_MEAL_ADDON_SELECTIONS_RESPONSE';
export const UPDATE_MEAL_ADDON_SELECTIONS_ERROR = 'UPDATE_MEAL_ADDON_SELECTIONS_ERROR';

export const updateMealAddonSelections = (mealAddonIds, mealAddonNames) =>
  makeApiRequest({
    requestType: UPDATE_MEAL_ADDON_SELECTIONS,
    method: RequestService.updateMealAddonSelections,
    params: { mealAddonIds },
    metadata: { mealAddonNames },
  });

export const UPDATE_OPT_IN_SELECTIONS = 'UPDATE_OPT_IN_SELECTIONS';
export const UPDATE_OPT_IN_SELECTIONS_REQUEST = 'UPDATE_OPT_IN_SELECTIONS_REQUEST';
export const UPDATE_OPT_IN_SELECTIONS_RESPONSE = 'UPDATE_OPT_IN_SELECTIONS_RESPONSE';
export const UPDATE_OPT_IN_SELECTIONS_ERROR = 'UPDATE_OPT_IN_SELECTIONS_ERROR';

export const updateOptInSelections = (optInIds, optInNames) => {
  return makeApiRequest({
    requestType: UPDATE_OPT_IN_SELECTIONS,
    method: RequestService.updateOptInSelections,
    params: { optInIds },
    metadata: { optInNames },
  });
};

export const UPDATE_EMAIL_SETTINGS = 'UPDATE_EMAIL_SETTINGS';
export const UPDATE_EMAIL_SETTINGS_REQUEST = 'UPDATE_EMAIL_SETTINGS_REQUEST';
export const UPDATE_EMAIL_SETTINGS_RESPONSE = 'UPDATE_EMAIL_SETTINGS_RESPONSE';
export const UPDATE_EMAIL_SETTINGS_ERROR = 'UPDATE_EMAIL_SETTINGS_ERROR';

export const updateEmailSettings = unsubscribeAll =>
  makeApiRequest({
    requestType: UPDATE_EMAIL_SETTINGS,
    method: RequestService.updateEmailSettings,
    params: { unsubscribeAll },
  });

export const UPDATE_NOTIFICATION_PREFERENCES = 'UPDATE_NOTIFICATION_PREFERENCES';

export const updateNotificationPreferences = params =>
  makeApiRequest({
    requestType: UPDATE_NOTIFICATION_PREFERENCES,
    method: RequestService.updateNotificationPreferences,
    params,
  });

export const UPDATE_ID_ME_USER = 'UPDATE_ID_ME_USER';
export const UPDATE_ID_ME_USER_RESPONSE = 'UPDATE_ID_ME_USER_RESPONSE';

export const updateIdMeUserResponse = accessToken =>
  makeApiRequest({
    requestType: UPDATE_ID_ME_USER,
    method: RequestService.idMeVerification,
    params: { token: accessToken },
  });

export const REDEEM_PAUSE_CREDIT = 'REDEEM_PAUSE_CREDIT';
export const REDEEM_PAUSE_CREDIT_RESPONSE = 'REDEEM_PAUSE_CREDIT_RESPONSE';

export const redeemPauseCredit = () =>
  makeApiRequest({
    requestType: REDEEM_PAUSE_CREDIT,
    method: RequestService.redeemPauseCredit,
  });

import get from 'lodash/get';

import { GET_RECEIPT_RESPONSE } from 'actions/baskets';
import { GET_BEHAVIORAL_DISCOUNTS_RESPONSE } from 'actions/behavioralDiscounts';

const initialState = { isLoading: true };

const addBehavioralDiscountsForMenu = (state, menuSlug, behavioralDiscounts) => {
  return {
    ...state,
    isLoading: false,
    [menuSlug]: behavioralDiscounts,
  };
};

const shouldAddBehavioralDiscounts = action => {
  return action.response && action.response.behavioralDiscounts;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BEHAVIORAL_DISCOUNTS_RESPONSE:
      return addBehavioralDiscountsForMenu(
        state,
        action.response.menuSlug,
        action.response.behavioralDiscounts
      );
    case GET_RECEIPT_RESPONSE: {
      if (shouldAddBehavioralDiscounts(action)) {
        return addBehavioralDiscountsForMenu(
          state,
          action.response.menuSlug,
          action.response.behavioralDiscounts
        );
      }
      return state;
    }
    default:
      return state;
  }
};

export const selectBehavioralDiscountsForMenu = (state, menuSlug) => get(state, menuSlug, []);
export const selectBehavioralDiscountsForPromotionalDisplay = (state, menuSlug) =>
  selectBehavioralDiscountsForMenu(state, menuSlug).filter(
    behavioralDiscount => behavioralDiscount.shouldDisplayBanner
  );
export const selectBehavioralDiscountsIsLoading = state => state.isLoading;

export const SEARCH_PARAM_CHANGE = 'SEARCH_PARAM_CHANGE';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS';
export const UPDATE_PAGE = 'UPDATE_PAGE';
export const SET_FAVORITE = 'SET_FAVORITE';

export const handleSearchParamChange = (name, value) => ({
  type: SEARCH_PARAM_CHANGE,
  name,
  value,
});

export const handleUpdatePage = page => ({
  type: UPDATE_PAGE,
  page,
});

export const handleClearFilter = name => ({
  type: CLEAR_FILTER,
  name,
});

export const handleClearAllFilters = () => ({
  type: CLEAR_ALL_FILTERS,
});

export const handleSetFavorite = isFavorited => ({
  type: SET_FAVORITE,
  isFavorited,
});

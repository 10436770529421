import { fetchWrapper, handleResponse, hcUrl, buildOpts } from 'services/RequestService/helpers';

const enterTempo = async () => {
  const response = await fetchWrapper(
    hcUrl('/enter-tempo'),
    buildOpts({
      method: 'POST',
    })
  );
  return handleResponse(response);
};

const exitTempo = async () => {
  const response = await fetchWrapper(
    hcUrl('/exit-tempo'),
    buildOpts({
      method: 'POST',
    })
  );
  return handleResponse(response);
};

export { enterTempo, exitTempo };

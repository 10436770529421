import { fetchWrapper, handleResponse, hcUrl, buildOpts } from 'services/RequestService/helpers';

export const getPastReceipt = async ({ params }) => {
  const { menuSlug } = params;

  const response = await fetchWrapper(
    hcUrl(`/weekly_baskets/${menuSlug}/past-receipt`),
    buildOpts()
  );
  return handleResponse(response);
};

import { makeApiRequest } from 'actions/api';
import { getPartnerDeals } from 'actions/partnerDeals';
import { getStreakOffers } from 'actions/streakOffers';
import { getUser } from 'actions/user';
import RequestService from 'services/RequestService';

export const GET_OFFER_REWARDS = 'GET_OFFER_REWARDS';
export const GET_OFFER_REWARDS_RESPONSE = 'GET_OFFER_REWARDS_RESPONSE';

export const getOfferRewards = () =>
  makeApiRequest({
    requestType: GET_OFFER_REWARDS,
    method: RequestService.getOfferRewards,
  });

export const bootstrapOfferRewards = () => dispatch => {
  dispatch(getUser());
  dispatch(getOfferRewards());
  dispatch(getPartnerDeals());
  dispatch(getStreakOffers());
};

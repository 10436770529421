import set from 'lodash/fp/set';
import get from 'lodash/get';
import omit from 'lodash/omit';
import reduce from 'lodash/reduce';

import {
  ADD_PAIRED_MEALS,
  REMOVE_PAIRED_MEAL,
  CLEAR_EDITED_BASKET,
  UNDO_BASKET_EDITS,
  SET_EDITED_BASKET,
} from 'actions/basketEdits';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PAIRED_MEALS:
      return set(action.hostMealId, action.pairedMealId, state);
    case REMOVE_PAIRED_MEAL:
      return omit(state, action.hostMealId);
    case UNDO_BASKET_EDITS:
    case SET_EDITED_BASKET:
      return reduce(
        action.selectedPairings,
        (hash, pair) => ({ ...hash, [pair.hostMealId]: pair.suggestedPairing.id }),
        {}
      );
    case CLEAR_EDITED_BASKET:
      return initialState;
    default:
      return state;
  }
};

export const selectPairingForHostMeal = (state, mealId) => get(state, mealId);

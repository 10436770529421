const initialState = [];

import { GET_BASKET_SUMMARIES_RESPONSE } from 'actions/baskets';

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BASKET_SUMMARIES_RESPONSE:
      return action.response.weeklyBaskets.map(basket => basket.menu.slug);
    default:
      return state;
  }
};

import classNames from 'classnames';

const CarbConscious = ({ className = '' }) => (
  <svg
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames('dietary-tag', className)}
    role="presentation"
    pointerEvents="none"
  >
    <title>Carb Conscious</title>
    <path
      d="M11.125 0.895242V0.875H15.25C17.125 0.875 18.625 2.375 18.625 4.25C18.625 5.55864 18.2597 6.50194 18.0389 6.9524C17.9412 7.15185 17.875 7.36852 17.875 7.59062V8H13.375V7.70416C13.375 7.40803 13.4641 7.11879 13.5877 6.84969C13.8094 6.36695 14.125 5.46626 14.125 4.25C14.125 2.50183 12.8211 1.07965 11.125 0.895242Z"
      fill="#99CE99"
    />
    <path
      d="M4.75 1.25C3.08211 1.25 1.75 2.58211 1.75 4.25C1.75 5.40076 2.04857 6.24789 2.2531 6.69316C2.38927 6.9896 2.5 7.3343 2.5 7.70416V8.79688C2.5 9.00398 2.33211 9.17188 2.125 9.17188C1.91789 9.17188 1.75 9.00398 1.75 8.79688L1.75 8.375H1C0.792893 8.375 0.625 8.20711 0.625 8C0.625 7.79289 0.792893 7.625 1 7.625H1.74723C1.7335 7.4265 1.66952 7.21947 1.57156 7.00622C1.3326 6.486 1 5.53177 1 4.25C1 2.16789 2.66789 0.5 4.75 0.5C5.97781 0.5 6.62192 0.806525 7.10053 1.0365C7.39994 1.18037 7.55837 1.25 7.75 1.25C7.94163 1.25 8.10006 1.18037 8.39947 1.0365C8.87808 0.806525 9.52219 0.5 10.75 0.5C11.009 0.5 11.2615 0.525804 11.5053 0.575H15.25C17.2907 0.575 18.925 2.20931 18.925 4.25C18.925 5.51867 18.5958 6.46219 18.3603 6.97491C18.2582 7.19717 18.1899 7.41751 18.1772 7.63207C18.2007 7.62743 18.2251 7.625 18.25 7.625H19C19.2071 7.625 19.375 7.79289 19.375 8C19.375 8.20711 19.2071 8.375 19 8.375H18.25V8.79688C18.25 9.00398 18.0821 9.17188 17.875 9.17188C17.6679 9.17188 17.5 9.00398 17.5 8.79688V8C17.5 7.91556 17.5279 7.83764 17.575 7.77496V7.70416C17.575 7.34905 17.6814 7.01544 17.8151 6.72446C18.023 6.2717 18.325 5.41386 18.325 4.25C18.325 2.54069 16.9593 1.175 15.25 1.175H12.9061C13.8722 1.85107 14.5 2.97258 14.5 4.25C14.5 5.53177 14.1674 6.486 13.9284 7.00622C13.8305 7.21947 13.7665 7.42651 13.7528 7.62502C13.9586 7.62651 14.125 7.79382 14.125 8C14.125 8.20711 13.9571 8.375 13.75 8.375V8.79688C13.75 9.00398 13.5821 9.17188 13.375 9.17188C13.1679 9.17188 13 9.00398 13 8.79688V8.375L12.25 8.375C12.0429 8.375 11.875 8.20711 11.875 8C11.875 7.79289 12.0429 7.625 12.25 7.625L13.0017 7.625C13.016 7.28512 13.1205 6.96835 13.2469 6.69316C13.4514 6.24789 13.75 5.40076 13.75 4.25C13.75 2.58211 12.4179 1.25 10.75 1.25C9.68676 1.25 9.1594 1.50342 8.73166 1.70897L8.69756 1.72537C8.42952 1.85437 8.12692 2 7.75 2C7.37308 2 7.07048 1.85437 6.80243 1.72537L6.76834 1.70897C6.3406 1.50342 5.81324 1.25 4.75 1.25Z"
      fill="#004400"
    />
    <path
      d="M2.875 8C2.875 7.79289 3.04289 7.625 3.25 7.625H4.75C4.95711 7.625 5.125 7.79289 5.125 8C5.125 8.20711 4.95711 8.375 4.75 8.375H3.25C3.04289 8.375 2.875 8.20711 2.875 8Z"
      fill="#004400"
    />
    <path
      d="M6.25 7.625C6.04289 7.625 5.875 7.79289 5.875 8C5.875 8.20711 6.04289 8.375 6.25 8.375H7.75C7.95711 8.375 8.125 8.20711 8.125 8C8.125 7.79289 7.95711 7.625 7.75 7.625H6.25Z"
      fill="#004400"
    />
    <path
      d="M8.875 8C8.875 7.79289 9.04289 7.625 9.25 7.625H10.75C10.9571 7.625 11.125 7.79289 11.125 8C11.125 8.20711 10.9571 8.375 10.75 8.375H9.25C9.04289 8.375 8.875 8.20711 8.875 8Z"
      fill="#004400"
    />
    <path
      d="M15.25 7.625C15.0429 7.625 14.875 7.79289 14.875 8C14.875 8.20711 15.0429 8.375 15.25 8.375H16.75C16.9571 8.375 17.125 8.20711 17.125 8C17.125 7.79289 16.9571 7.625 16.75 7.625H15.25Z"
      fill="#004400"
    />
    <path
      d="M2.125 10.0156C2.33211 10.0156 2.5 10.1835 2.5 10.3906V11.9844C2.5 12.1915 2.33211 12.3594 2.125 12.3594C1.91789 12.3594 1.75 12.1915 1.75 11.9844V10.3906C1.75 10.1835 1.91789 10.0156 2.125 10.0156Z"
      fill="#004400"
    />
    <path
      d="M13.75 10.3906C13.75 10.1835 13.5821 10.0156 13.375 10.0156C13.1679 10.0156 13 10.1835 13 10.3906V11.9844C13 12.1915 13.1679 12.3594 13.375 12.3594C13.5821 12.3594 13.75 12.1915 13.75 11.9844V10.3906Z"
      fill="#004400"
    />
    <path
      d="M2.125 13.2031C2.33211 13.2031 2.5 13.371 2.5 13.5781V14.375C2.5 14.5821 2.66789 14.75 2.875 14.75H3.6875C3.89461 14.75 4.0625 14.9179 4.0625 15.125C4.0625 15.3321 3.89461 15.5 3.6875 15.5H2.875C2.25368 15.5 1.75 14.9963 1.75 14.375V13.5781C1.75 13.371 1.91789 13.2031 2.125 13.2031Z"
      fill="#004400"
    />
    <path
      d="M13.75 13.5781C13.75 13.371 13.5821 13.2031 13.375 13.2031C13.1679 13.2031 13 13.371 13 13.5781V14.375C13 14.5821 12.8321 14.75 12.625 14.75H11.8125C11.6054 14.75 11.4375 14.9179 11.4375 15.125C11.4375 15.3321 11.6054 15.5 11.8125 15.5H14.4062C14.6134 15.5 14.7812 15.3321 14.7812 15.125C14.7812 14.9179 14.6134 14.75 14.4062 14.75H13.686C13.7274 14.6327 13.75 14.5065 13.75 14.375V13.5781Z"
      fill="#004400"
    />
    <path
      d="M4.9375 15.125C4.9375 14.9179 5.10539 14.75 5.3125 14.75H6.9375C7.14461 14.75 7.3125 14.9179 7.3125 15.125C7.3125 15.3321 7.14461 15.5 6.9375 15.5H5.3125C5.10539 15.5 4.9375 15.3321 4.9375 15.125Z"
      fill="#004400"
    />
    <path
      d="M8.5625 14.75C8.35539 14.75 8.1875 14.9179 8.1875 15.125C8.1875 15.3321 8.35539 15.5 8.5625 15.5H10.1875C10.3946 15.5 10.5625 15.3321 10.5625 15.125C10.5625 14.9179 10.3946 14.75 10.1875 14.75H8.5625Z"
      fill="#004400"
    />
    <path
      d="M18.25 10.3906C18.25 10.1835 18.0821 10.0156 17.875 10.0156C17.6679 10.0156 17.5 10.1835 17.5 10.3906V11.9844C17.5 12.1915 17.6679 12.3594 17.875 12.3594C18.0821 12.3594 18.25 12.1915 18.25 11.9844V10.3906Z"
      fill="#004400"
    />
    <path
      d="M17.875 13.2031C18.0821 13.2031 18.25 13.371 18.25 13.5781V14.375C18.25 14.9963 17.7463 15.5 17.125 15.5H16.2187C16.0116 15.5 15.8437 15.3321 15.8437 15.125C15.8437 14.9179 16.0116 14.75 16.2187 14.75H17.125C17.3321 14.75 17.5 14.5821 17.5 14.375V13.5781C17.5 13.371 17.6679 13.2031 17.875 13.2031Z"
      fill="#004400"
    />
  </svg>
);

CarbConscious.propTypes = {
  className: PropTypes.string,
};

export default CarbConscious;

import {
  GET_MEAL_ADDONS_REQUEST,
  GET_MEAL_ADDONS_RESPONSE,
  GET_MEAL_ADDONS_ERROR,
} from 'actions/mealAddons';
import {
  UPDATE_MEAL_ADDON_SELECTIONS_REQUEST,
  UPDATE_MEAL_ADDON_SELECTIONS_RESPONSE,
} from 'actions/user';

const initialState = {
  results: [],
  loading: false,
  failed: false,
  updating: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MEAL_ADDONS_REQUEST:
      return { ...state, loading: true, failed: false };
    case GET_MEAL_ADDONS_RESPONSE:
      return { ...state, loading: false, results: action.response.mealAddons };
    case GET_MEAL_ADDONS_ERROR:
      return { ...state, loading: false, failed: true };
    case UPDATE_MEAL_ADDON_SELECTIONS_REQUEST:
      return { ...state, updating: true };
    case UPDATE_MEAL_ADDON_SELECTIONS_RESPONSE:
      return { ...state, updating: false };
    default:
      return state;
  }
};

export const selectMealAddonsResults = state => state.results;
export const selectMealAddonsLoading = state => state.loading;
export const selectMealAddonsFailed = state => state.failed;
export const selectMealAddonsUpdating = state => state.updating;

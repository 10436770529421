import omit from 'lodash/omit';

import {
  CLEAR_FILTER,
  CLEAR_ALL_FILTERS,
  SEARCH_PARAM_CHANGE,
  UPDATE_PAGE,
  SET_FAVORITE,
} from 'actions/searchParams';

const LIMIT = 12;
const OFFSET = 0;
const FAVORITE = false;

const startState = {
  sort: 'date',
  limit: LIMIT,
  offset: OFFSET,
  favorite: FAVORITE,
};

function updateSearchParams(searchParams, name, value) {
  return value === '' ? omit(searchParams, name) : { ...searchParams, [name]: value };
}

export default (state = startState, action) => {
  const { name, value, type, page } = action;

  switch (type) {
    case SEARCH_PARAM_CHANGE:
      return updateSearchParams({ ...state, offset: OFFSET }, name, value);
    case CLEAR_FILTER:
      return omit(
        {
          ...state,
          offset: OFFSET,
        },
        name
      );
    case CLEAR_ALL_FILTERS:
      return {
        ...startState,
        sort: state.sort,
      };
    case UPDATE_PAGE:
      return {
        ...state,
        offset: state.limit * (page - 1),
      };
    case SET_FAVORITE:
      return {
        ...state,
        favorite: action.isFavorited,
      };
    default:
      return state;
  }
};

import get from 'lodash/get';

import {
  SKIP_BASKET_RESPONSE,
  UNSKIP_BASKET_RESPONSE,
  ADD_BASKET_RESPONSE,
  UPDATE_BASKET_ADDRESS_RESPONSE,
  UPDATE_BASKET_RESPONSE,
  UNDONATE_BASKET_RESPONSE,
  DONATE_BASKET_RESPONSE,
  GET_BASKET_RESPONSE,
  GET_SKELETON_BASKET_RESPONSE,
  GET_BASKET_SUMMARIES_RESPONSE,
  GET_TRACKING_RESPONSE,
  GET_UPCOMING_RECEIPT_RESPONSE,
  UNSKIP_BASKET_ERROR,
} from 'actions/baskets';

const initialState = {};

const initializeBaskets = (state, baskets) => {
  const basketHash = baskets.reduce(
    (hash, basket) => ({
      ...hash,
      [basket.menu.slug]: {
        ...basket,
        meals: [],
      },
    }),
    {}
  );

  return {
    ...basketHash,
    ...state,
  };
};

const basketSuccess = (state, menuSlug, basket) => {
  const existingBasket = state[menuSlug];
  return {
    ...state,
    [menuSlug]: { ...existingBasket, ...basket },
  };
};

const addTrackingLink = (state, action) => {
  const trackingResponse = action.response.tracking;
  const menuSlug = action.params.menuSlug;
  const trackingState = {
    ...state[menuSlug],
    newTrackingLink: trackingResponse ? trackingResponse.trackingLink : null,
  };

  return {
    ...state,
    [menuSlug]: trackingState,
  };
};

const addReceiptTotal = (state, action) => {
  const totalFormatted = action.response.totalFormatted;
  const menuSlug = action.params.menuSlug;
  return {
    ...state,
    [menuSlug]: {
      ...state[menuSlug],
      receiptTotalFormatted: totalFormatted ? totalFormatted : null,
    },
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BASKET_SUMMARIES_RESPONSE:
      return initializeBaskets(state, action.response.weeklyBaskets);
    case UNSKIP_BASKET_ERROR:
      return {
        ...state,
        [action.params.menuSlug]: {
          ...state[action.params.menuSlug],
          error: action.response.body,
        },
      };
    case SKIP_BASKET_RESPONSE:
    case UNSKIP_BASKET_RESPONSE:
    case UNDONATE_BASKET_RESPONSE:
    case UPDATE_BASKET_ADDRESS_RESPONSE:
    case GET_BASKET_RESPONSE:
    case GET_SKELETON_BASKET_RESPONSE:
    case DONATE_BASKET_RESPONSE:
      return basketSuccess(state, action.params.menuSlug, action.response.weeklyBasket);
    case ADD_BASKET_RESPONSE:
    case UPDATE_BASKET_RESPONSE:
      return basketSuccess(
        state,
        action.response.weeklyBasket.menu.slug,
        action.response.weeklyBasket
      );
    case GET_TRACKING_RESPONSE:
      return addTrackingLink(state, action);
    case GET_UPCOMING_RECEIPT_RESPONSE:
      return addReceiptTotal(state, action);
    default:
      return state;
  }
};

export const selectBasket = (state, menuSlug) => get(state, menuSlug, { meals: [] });
export const selectMealsForBasket = (state, menuSlug) =>
  get(selectBasket(state, menuSlug), 'meals') || [];

export const selectMealOptionSelectionsForBasket = (state, menuSlug) =>
  get(selectBasket(state, menuSlug), 'mealOptionSelections') || {};

export const selectMealOptionSelectionsForBasketAndMeal = (state, menuSlug, mealId) =>
  get(selectMealOptionSelectionsForBasket(state, menuSlug), mealId, []);

import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_MENU_MEAL = 'GET_MENU_MEAL';
export const GET_MENU_MEAL_REQUEST = 'GET_MENU_MEAL_REQUEST';
export const GET_MENU_MEAL_RESPONSE = 'GET_MENU_MEAL_RESPONSE';
export const GET_MENU_MEAL_ERROR = 'GET_MENU_MEAL_ERROR';

export const getMenuMeal = (menuSlug, mealId) =>
  makeApiRequest({
    requestType: GET_MENU_MEAL,
    method: RequestService.getMenuMeal,
    params: { menuSlug, mealId },
  });

import {
  GET_PAST_RECEIPT_REQUEST,
  GET_PAST_RECEIPT_RESPONSE,
  GET_PAST_RECEIPT_ERROR,
} from 'actions/pastReceipts';

const initialState = {
  loading: false,
  error: null,
  receipts: {},
};

const addReceipt = (receipts, receipt) => {
  return {
    ...receipts,
    [receipt.menu.id]: receipt,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PAST_RECEIPT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PAST_RECEIPT_RESPONSE:
      return {
        ...state,
        loading: false,
        error: false,
        receipts: addReceipt(state.receipts, action.response.receipt),
      };
    case GET_PAST_RECEIPT_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export const selectReceiptLoading = state => state.loading;
export const selectReceipt = (state, menuSlug) => state.receipts[menuSlug];

import { decamelize } from 'helpers/camelize';

import { fetchWrapper, handleResponse, buildOpts, hcUrl, objectToQueryString } from '../helpers';

const getQuickFilters = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl(`/quick_filters?${objectToQueryString(decamelize(params, true))}`),
    buildOpts()
  );
  return handleResponse(response);
};

export { getQuickFilters };

import { FetchTypeError } from './errors';

// Wrap fetch and throw a fetch-specific error,
// so callers can distinguish "fetch failed" from other exceptions
export const fetchWrapper = async (...args) => {
  try {
    const result = await fetch(...args);
    return result;
  } catch (e) {
    if (e instanceof TypeError) {
      throw new FetchTypeError(e);
    } else {
      throw e;
    }
  }
};

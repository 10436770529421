import get from 'lodash/get';

import { GET_BASKET_SUMMARIES_RESPONSE } from 'actions/baskets';
import { GET_BOTTOM_SHEET_RESPONSE } from 'actions/userIncentives';
import { transformMealForBottomSheet } from 'services/RequestService/helpers';

const initialState = {};

const initializeModalMeals = (currentMenusData, menuSlugs) => {
  const menusHash = menuSlugs.reduce((hash, slug) => ({ ...hash, [slug]: [] }), {});

  return {
    ...menusHash,
    ...currentMenusData,
  };
};

const addModalMealsForMenu = (currentMenusData, menuSlug, bottomSheet) => ({
  ...currentMenusData,
  [menuSlug]: {
    modalMeals: bottomSheet.modalMeals.map(mm => transformMealForBottomSheet(mm)),
    modalMealsCategory: bottomSheet.modalMealsCategory,
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BASKET_SUMMARIES_RESPONSE:
      return initializeModalMeals(
        state,
        action.response.weeklyBaskets.map(wb => wb.menu.slug)
      );
    case GET_BOTTOM_SHEET_RESPONSE:
      return addModalMealsForMenu(
        state,
        action.response.userIncentives.menuSlug,
        action.response.userIncentives.bottomSheet
      );
    default:
      return state;
  }
};

export const selectModalMealsForMenu = (state, slug) => get(state, [slug, 'modalMeals'], []);
export const selectModalMealsCategory = (state, slug) =>
  get(state, [slug, 'modalMealsCategory'], {});

import {
  ACCOUNT_PAST_ORDERS_REQUEST,
  ACCOUNT_PAST_ORDERS_RESPONSE,
  ACCOUNT_PAST_ORDERS_ERROR,
} from 'actions/orderHistory';

const initialState = {
  pastOrders: {},
  fullPastOrders: {},
  loading: false,
  failed: false,
  showLoading: false,
  showFailed: false,
  numberOfPages: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_PAST_ORDERS_REQUEST:
      return { ...state, loading: true, failed: false };
    case ACCOUNT_PAST_ORDERS_RESPONSE:
      return {
        ...state,
        loading: false,
        pastOrders: {
          ...state.pastOrders,
          [action.response.page]: action.response.weeklyBaskets,
        },
        numberOfPages: action.response.pages,
      };
    case ACCOUNT_PAST_ORDERS_ERROR:
      return { ...state, loading: false, failed: true };
    default:
      return state;
  }
};

export const selectOrderHistoryPastOrders = (state, page) => state.pastOrders[page] || [];
export const selectOrderHistoryLoading = state => state.loading;
export const selectOrderHistoryNumberOfPages = state => state.numberOfPages;
export const selectOrderHistoryFailed = state => state.failed;

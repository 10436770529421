import get from 'lodash/get';

import {
  GET_SKELETON_BASKET_RESPONSE,
  GET_SKELETON_BASKET_ERROR,
  GET_SKELETON_BASKET_REQUEST,
  SKIP_BASKET_REQUEST,
} from 'actions/baskets';

const initialState = {};
const uninitializedStatus = Object.freeze({
  needsInitialization: true,
  isLoading: false,
  isError: false,
});

const basketRequest = (state, menuSlug) => {
  const nextBasketState = {
    ...state[menuSlug],
    needsInitialization: false,
    isLoading: true,
  };

  return {
    ...state,
    [menuSlug]: nextBasketState,
  };
};

const basketSuccess = (state, menuSlug) => ({
  ...state,
  [menuSlug]: {
    needsInitialization: false,
    isLoading: false,
    isError: false,
  },
});

const basketError = (state, menuSlug) => ({
  ...state,
  [menuSlug]: {
    needsInitialization: false,
    isLoading: false,
    isError: true,
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SKELETON_BASKET_REQUEST:
      return basketRequest(state, action.params.menuSlug);
    case GET_SKELETON_BASKET_RESPONSE:
      return basketSuccess(state, action.params.menuSlug);
    case GET_SKELETON_BASKET_ERROR:
      return basketError(state, action.params.menuSlug);
    case SKIP_BASKET_REQUEST:
      return { ...state, [action.params.menuSlug]: uninitializedStatus };
    default:
      return state;
  }
};

const selectBasketStatus = (state, menuSlug) =>
  get(state, ['basketSkeletonStatus', menuSlug], uninitializedStatus);

export const needsInitialization = (state, menuSlug) =>
  get(selectBasketStatus(state, menuSlug), 'needsInitialization', true);
export const isBasketLoading = (state, menuSlug) =>
  get(selectBasketStatus(state, menuSlug), 'isLoading', false);
export const isBasketError = (state, menuSlug) =>
  get(selectBasketStatus(state, menuSlug), 'isError', false);
export const removedMeals = (state, menuSlug) =>
  get(selectBasketStatus(state, menuSlug), 'removedMeals', []);

import { GET_MEALS_RESPONSE } from 'actions/meals';

const transformMealsResponse = response =>
  response.mealSelections.map(mealSelection => mealSelection.id);

export default (state = [], action) => {
  const { response, type } = action;

  switch (type) {
    case GET_MEALS_RESPONSE:
      return transformMealsResponse(response);
    default:
      return state;
  }
};

import {
  fetchWrapper,
  handleResponse,
  formatBody,
  hcUrl,
  buildOpts,
  transformFavoriteResponse,
  transformFavoritesResponse,
} from 'services/RequestService/helpers';

const getFavorites = async () => {
  const response = await fetchWrapper(hcUrl('/favorites'), buildOpts());
  return handleResponse(response, transformFavoritesResponse);
};

const addFavorite = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/favorites'),
    buildOpts({
      method: 'POST',
      body: formatBody(params),
    })
  );
  return handleResponse(response, transformFavoriteResponse);
};

const removeFavorite = async ({ params: { id } }) => {
  const response = await fetchWrapper(
    hcUrl(`/favorites/${id}`),
    buildOpts({
      method: 'DELETE',
    })
  );
  return handleResponse(response, transformFavoriteResponse);
};

export { getFavorites, addFavorite, removeFavorite };

const initialState = {};

import { GET_BASKET_SUMMARIES_RESPONSE } from 'actions/baskets';
import { GET_MENU_MEAL_RESPONSE } from 'actions/menuMeals';
import { GET_MENU_RESPONSE } from 'actions/menus';

const initializeMenuMeals = (currentMenusData, menuSlugs) => {
  const menusHash = menuSlugs.reduce((hash, slug) => ({ ...hash, [slug]: [] }), {});

  return {
    ...menusHash,
    ...currentMenusData,
  };
};

const addMealsForMenu = (currentMenusData, menuSlug, menu) => ({
  ...currentMenusData,
  [menuSlug]: menu.meals,
});

const updateMealForMenu = (currentMenusData, menuSlug, updatedMeal) => {
  const existingMeals = currentMenusData[menuSlug] || [];

  const mealExists = existingMeals.some(meal => meal.id === updatedMeal.id);

  return {
    ...currentMenusData,
    [menuSlug]: mealExists
      ? existingMeals.map(meal => (meal.id === updatedMeal.id ? { ...meal, ...updatedMeal } : meal))
      : [...existingMeals, updatedMeal],
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BASKET_SUMMARIES_RESPONSE:
      return initializeMenuMeals(
        state,
        action.response.weeklyBaskets.map(wb => wb.menu.slug)
      );
    case GET_MENU_RESPONSE:
      return addMealsForMenu(state, action.params.menuSlug, action.response.menu);
    case GET_MENU_MEAL_RESPONSE:
      return updateMealForMenu(state, action.params.menuSlug, action.response.meal);
    default:
      return state;
  }
};

/**
 * Get all of the mealAddons across all of the menus
 * So you can go from a mealId to its mealAddon without needing to eat the whole meal
 */
export const selectMealAddons = state => {
  const mealAddons = {};
  Object.values(state)
    .flatMap(x => x)
    .forEach(meal => {
      if (meal.mealAddonId) {
        mealAddons[meal.id] = {
          id: meal.mealAddonId,
          name: meal.mealAddonName,
          displayName: meal.mealAddonDisplayName,
          pluralizedDisplayName: meal.mealAddonPluralizedDisplayName,
        };
      }
    });
  return mealAddons;
};

export const selectOptIns = state => {
  const optIns = {};
  Object.values(state)
    .flatMap(x => x)
    .forEach(meal => {
      if (Boolean(meal.optIns) && Object.keys(meal.optIns).length > 0) {
        optIns[meal.id] = meal.optIns;
      }
    });
  return optIns;
};

export const selectMealsForMenu = (state, slug) => state[slug] || [];
export const selectNonDonationMealsForMenu = (state, slug) =>
  selectMealsForMenu(state, slug).filter(meal => !meal.isDonation);

import { combineReducers } from 'redux';

import comments from './comments';
import reviews, * as fromReviews from './reviews';

export default combineReducers({
  comments,
  reviews,
});

export const selectComments = state => state.comments;
export const selectReviews = state => state.reviews;

export const selectReview = (state, id) => fromReviews.selectReview(selectReviews(state), id);

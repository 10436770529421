export const FREE_DESSERT = 'Free Dessert';

export const SIDES = 'Sides';

export const MY_FAVORITES = 'myFavorites';

export const FILTERED_MEALS_NO_RESULTS_SUBHEADING =
  // eslint-disable-next-line quotes
  "We're sorry, we couldn't find any meals that match your filters! We recommend trying other combinations or exploring different menus.";

export const MEAL_FILTERS = [
  'Protein-Packed',
  'Calorie-Conscious',
  'Fiber-Rich',
  'Dietitian Approved',
];

import qs from 'qs';

/**
 * @param {Location} location - browser Location object
 * @param {string} param - query param to lookup
 * @returns {string|undefined}
 * Would be nice to use URLSearchParams instead, but not supported on IE and don't see a good polyfill
 */
export const getQueryParam = (location, param) => {
  const queryString = location.search === '' ? location.search : location.search.slice(1);
  return qs.parse(queryString)[param];
};

import classNames from 'classnames';

const Pescatarian = ({ className = '' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames('dietary-tag', className)}
  >
    <title>Pescatarian</title>
    <path
      d="M17.0153 8.23984C14.0427 7.125 11.813 8.23984 11.813 8.23984C11.0246 8.23979 10.2685 7.92656 9.71101 7.36905C9.15354 6.81154 8.84033 6.05541 8.84033 5.267V3.40955H13.2993C14.2849 3.40955 15.23 3.80105 15.9269 4.49794C16.6238 5.19482 17.0153 6.14 17.0153 7.12554V8.23984Z"
      fill="#99CE99"
    />
    <path
      d="M11.2119 8.5551C11.1876 8.55503 11.1634 8.55269 11.1396 8.54812C10.3764 8.394 9.68995 7.98086 9.19642 7.37866C8.70288 6.77646 8.43261 6.0222 8.4314 5.24359V4.11812C8.43169 3.82167 8.54961 3.53744 8.75923 3.32781C8.96885 3.11819 9.25307 3.00029 9.54952 3H13.3018C14.394 3.00122 15.4411 3.43564 16.2134 4.20796C16.9858 4.98027 17.4202 6.02741 17.4214 7.11962V7.87C17.4214 7.96753 17.3827 8.06107 17.3137 8.13003C17.2447 8.199 17.1512 8.23774 17.0537 8.23774C16.9561 8.23774 16.8626 8.199 16.7936 8.13003C16.7246 8.06107 16.6859 7.96753 16.6859 7.87V7.11962C16.6848 6.22242 16.3279 5.36228 15.6935 4.72786C15.0591 4.09344 14.199 3.73655 13.3018 3.73548H9.54952C9.44807 3.73558 9.3508 3.77593 9.27906 3.84766C9.20732 3.9194 9.16698 4.01667 9.16688 4.11812V5.24359C9.16768 5.85215 9.37882 6.44172 9.7645 6.91245C10.1502 7.38318 10.6867 7.70613 11.2832 7.8266C11.3728 7.84432 11.4526 7.89474 11.5071 7.96805C11.5616 8.04136 11.5868 8.13231 11.5779 8.2232C11.569 8.31409 11.5266 8.39842 11.4589 8.45977C11.3913 8.52112 11.3032 8.5551 11.2119 8.5551Z"
      fill="#004400"
    />
    <path
      d="M3.26451 7.44737C3.19287 7.316 3.07951 7.21223 2.94234 7.15245C2.80516 7.09266 2.65202 7.08028 2.50702 7.11725C2.36202 7.15422 2.23344 7.23843 2.14163 7.35659C2.04982 7.47475 1.99999 7.62013 2 7.76977V10.5159C2 11.2526 2.05777 11.8972 2.64696 12.2501C2.69646 12.2796 2.73744 12.3214 2.76591 12.3715C2.79437 12.4216 2.80937 12.4783 2.80937 12.5359C2.80937 12.5935 2.79437 12.6501 2.76591 12.7002C2.73744 12.7503 2.69646 12.7922 2.64696 12.8216C2.05777 13.1746 2 13.8192 2 14.5559V17.302C1.99995 17.4516 2.04975 17.597 2.14155 17.7152C2.23334 17.8334 2.36193 17.9177 2.50694 17.9547C2.65195 17.9916 2.80511 17.9793 2.9423 17.9195C3.07949 17.8597 3.19287 17.7559 3.26451 17.6246L6.04 12.5359L3.26451 7.44737Z"
      fill="#99CE99"
    />
    <path
      d="M2.68814 18.2926C2.50812 18.2928 2.33537 18.2214 2.2079 18.0943C2.08043 17.9672 2.00864 17.7947 2.00833 17.6146V14.1557C2.005 13.8524 2.07224 13.5526 2.20471 13.2797C2.33717 13.0069 2.53124 12.7686 2.77161 12.5837C2.53101 12.3999 2.3367 12.1625 2.20417 11.8902C2.07165 11.618 2.00458 11.3186 2.00833 11.0159V7.55695C2.00841 7.41162 2.05524 7.27017 2.14187 7.15349C2.2285 7.03681 2.35034 6.95108 2.48943 6.90896C2.62852 6.86684 2.77747 6.87056 2.91428 6.91958C3.05109 6.9686 3.1685 7.06031 3.24919 7.18117L6.34876 11.8303C6.49832 12.0538 6.57818 12.3167 6.57818 12.5856C6.57818 12.8546 6.49832 13.1174 6.34876 13.3409L3.24952 17.9904C3.1882 18.0833 3.10481 18.1594 3.00684 18.2122C2.90887 18.2649 2.7994 18.2925 2.68814 18.2926ZM2.68728 7.54789L2.67905 11.0159C2.67555 11.2146 2.71828 11.4114 2.80383 11.5908C2.88938 11.7702 3.01545 11.9273 3.17207 12.0496C3.25518 12.1121 3.32262 12.1931 3.36906 12.2861C3.41551 12.3792 3.43968 12.4817 3.43968 12.5857C3.43968 12.6897 3.41551 12.7923 3.36906 12.8853C3.32262 12.9783 3.25518 13.0593 3.17207 13.1218C3.01541 13.2442 2.8893 13.4012 2.80375 13.5807C2.7182 13.7601 2.67551 13.957 2.67905 14.1557V17.6146L5.7909 12.9699C5.86679 12.8564 5.90729 12.723 5.90729 12.5865C5.90729 12.45 5.86679 12.3165 5.7909 12.2031L2.6915 7.55393C2.69035 7.55175 2.68891 7.54973 2.68728 7.54789Z"
      fill="#004400"
    />
    <path
      d="M14.5004 18.3261C13.2018 18.3261 9.8 17.8288 6.26605 13.2231C6.11429 13.0268 6.0331 12.7852 6.03561 12.5371C6.03812 12.2891 6.12417 12.0491 6.27987 11.856C9.81291 7.47302 13.2056 7 14.5004 7C17.7355 7 20.5913 9.53894 22.0057 11.0525C22.1965 11.2544 22.3037 11.5211 22.3055 11.7989C22.3074 12.0767 22.2038 12.3449 22.0157 12.5493C21.855 12.7262 21.6648 12.9271 21.448 13.1426L21.555 13.2494C21.6588 13.3527 21.7411 13.4755 21.797 13.6108C21.8529 13.7461 21.8813 13.8911 21.8806 14.0375C21.881 14.1816 21.8527 14.3242 21.7974 14.4572C21.742 14.5902 21.6608 14.7109 21.5583 14.8121C20.2359 16.1246 17.5608 18.3261 14.5004 18.3261ZM14.5004 7.72801C10.8279 7.72801 7.71369 11.2374 6.84663 12.3129C6.79368 12.379 6.76458 12.461 6.76402 12.5457C6.76346 12.6304 6.79149 12.7129 6.84356 12.7797C7.71081 13.9101 10.8261 17.5981 14.5004 17.5981C17.3 17.5981 19.8027 15.529 21.0454 14.2956C21.0796 14.2616 21.1066 14.2212 21.125 14.1767C21.1434 14.1321 21.1528 14.0844 21.1526 14.0362C21.1529 13.9858 21.1431 13.9357 21.1238 13.8891C21.1044 13.8424 21.076 13.8001 21.0402 13.7645L20.92 13.6442C20.92 13.6442 21.5434 13.2468 21.0402 13.6001C21.001 13.6288 20.9566 13.6493 20.9094 13.6606C20.8622 13.6718 20.8132 13.6736 20.7654 13.6656C20.7175 13.6577 20.6717 13.6403 20.6307 13.6144C20.5897 13.5885 20.5542 13.5547 20.5265 13.5149L20.469 13.3827C20.4589 13.3353 20.4583 13.2863 20.4673 13.2386C20.4764 13.191 20.4948 13.1456 20.5217 13.1052C20.5485 13.0648 20.5831 13.0301 20.6236 13.0033C21.3833 12.2401 20.7508 12.9001 21.4742 12.1009C21.5383 12.0313 21.5765 11.8989 21.5759 11.8043C21.5754 11.7098 21.5391 11.6189 21.4742 11.5501C20.1402 10.1223 17.4605 7.72801 14.5004 7.72801Z"
      fill="#004400"
    />
    <path
      d="M17.8696 16.4088H13.8604C13.4775 16.4087 13.0983 16.4841 12.7445 16.6307C12.3908 16.7772 12.0693 16.9919 11.7985 17.2627C11.5278 17.5335 11.313 17.8549 11.1665 18.2087C11.02 18.5624 10.9446 18.9416 10.9446 19.3245V20.418H14.2249C15.1914 20.4177 16.1182 20.0336 16.8016 19.3501C17.4849 18.6666 17.8687 17.7396 17.8687 16.7731L17.8696 16.4088Z"
      fill="#99CE99"
    />
    <path
      d="M14.2211 20.7858H11.636C11.3443 20.7854 11.0645 20.6694 10.8582 20.4631C10.6519 20.2568 10.5359 19.9771 10.5356 19.6853V19.316C10.5366 18.4368 10.8863 17.5939 11.5081 16.9722C12.1298 16.3506 12.9727 16.0009 13.8519 16H17.5445C17.7383 16.0002 17.9241 16.0773 18.0612 16.2144C18.1982 16.3514 18.2753 16.5373 18.2756 16.7311C18.2744 17.8061 17.8468 18.8367 17.0867 19.5968C16.3266 20.3569 15.2961 20.7845 14.2211 20.7858ZM13.8519 16.7239C13.1646 16.7245 12.5057 16.9978 12.0196 17.4838C11.5336 17.9698 11.2602 18.6287 11.2594 19.316V19.6853C11.2596 19.7852 11.2993 19.8809 11.3699 19.9515C11.4405 20.022 11.5362 20.0618 11.636 20.0619H14.2211C15.1038 20.0607 15.95 19.7095 16.5742 19.0853C17.1984 18.4611 17.5496 17.6149 17.5508 16.7322L17.5436 16.7248L13.8519 16.7239Z"
      fill="#004400"
    />
    <path
      d="M19.3912 12.417C19.7367 12.417 20.0167 12.1369 20.0167 11.7914C20.0167 11.446 19.7367 11.1659 19.3912 11.1659C19.0457 11.1659 18.7656 11.446 18.7656 11.7914C18.7656 12.1369 19.0457 12.417 19.3912 12.417Z"
      fill="#004400"
    />
    <path
      d="M17.6032 15C17.5257 14.9999 17.4501 14.9802 17.3858 14.9434C17.3291 14.9111 16 14.1324 16 12.2488C16 10.3652 17.3291 9.58649 17.3858 9.55422C17.4728 9.50607 17.5785 9.48908 17.68 9.50691C17.7815 9.52473 17.8708 9.57594 17.9286 9.6495C17.9864 9.72307 18.0081 9.8131 17.989 9.9002C17.9699 9.9873 17.9115 10.0645 17.8265 10.1152C17.7808 10.1424 16.7904 10.7467 16.7904 12.2488C16.7904 13.7537 17.7844 14.3576 17.8267 14.3824C17.8967 14.4231 17.9497 14.4819 17.9777 14.5502C18.0058 14.6185 18.0074 14.6927 17.9824 14.7619C17.9573 14.8311 17.907 14.8915 17.8388 14.9344C17.7706 14.9773 17.6881 15.0003 17.6034 15H17.6032Z"
      fill="#004400"
    />
  </svg>
);

Pescatarian.propTypes = {
  className: PropTypes.string,
};

export default Pescatarian;

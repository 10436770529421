import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_TASTE_PROFILE = 'GET_TASTE_PROFILE';
export const GET_TASTE_PROFILE_REQUEST = 'GET_TASTE_PROFILE_REQUEST';
export const GET_TASTE_PROFILE_RESPONSE = 'GET_TASTE_PROFILE_RESPONSE';
export const GET_TASTE_PROFILE_ERROR = 'GET_TASTE_PROFILE_ERROR';

export const UPDATE_TASTE_PROFILE = 'UPDATE_TASTE_PROFILE';
export const UPDATE_TASTE_PROFILE_REQUEST = 'UPDATE_TASTE_PROFILE_REQUEST';
export const UPDATE_TASTE_PROFILE_RESPONSE = 'UPDATE_TASTE_PROFILE_RESPONSE';
export const UPDATE_TASTE_PROFILE_ERROR = 'UPDATE_TASTE_PROFILE_ERROR';

export const getTasteProfile = () =>
  makeApiRequest({ requestType: GET_TASTE_PROFILE, method: RequestService.getTasteProfile });

export const updateTasteProfile = params =>
  makeApiRequest({
    requestType: UPDATE_TASTE_PROFILE,
    method: RequestService.updateTasteProfile,
    metadata: {
      preferences: params.preferences.reduce((acc, preference) => {
        acc[preference.name] = preference.interested;
        return acc;
      }, {}),
      avoidances: params.allergies.reduce((acc, allergy) => {
        acc[allergy.name] = allergy.avoid;
        return acc;
      }, {}),
    },
    params,
  });

import { GET_OFFER_REWARDS_RESPONSE } from 'actions/offerRewards';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFER_REWARDS_RESPONSE:
      return action.response.offerRewards;
    default:
      return state;
  }
};

export const selectWeeklyBasket = state => state.weeklyBasket;
export const selectCurrentMenuSlug = state => state.currentMenuSlug;

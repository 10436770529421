import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_OPT_INS = 'GET_OPT_INS';
export const GET_OPT_INS_REQUEST = 'GET_OPT_INS_REQUEST';
export const GET_OPT_INS_RESPONSE = 'GET_OPT_INS_RESPONSE';
export const GET_OPT_INS_ERROR = 'GET_OPT_INS_ERROR';

export const getOptIns = () =>
  makeApiRequest({
    requestType: GET_OPT_INS,
    method: RequestService.getOptIns,
  });

import set from 'lodash/fp/set';
import get from 'lodash/get';

import {
  CHANGE_MEAL_OPTION,
  UNDO_MEAL_OPTIONS,
  SET_EDITED_BASKET,
  CLEAR_EDITED_BASKET,
  UNDO_BASKET_EDITS,
} from 'actions/basketEdits';
import {
  ADD_BASKET_RESPONSE,
  UPDATE_BASKET_RESPONSE,
  UNDONATE_BASKET_RESPONSE,
  DONATE_BASKET_RESPONSE,
} from 'actions/baskets';
import { ROUTE_CHANGE } from 'actions/routes';
import * as Routes from 'helpers/routes';

export const setMealOptions = (mealOptionSelections, mealId) =>
  get(mealOptionSelections, mealId, []).reduce(
    (hash, option) => ({ ...hash, [option.mealOptionGroupId]: option.mealOptionId }),
    {}
  );

export const updateBasket = ({ meals, mealOptionSelections }) =>
  meals.reduce(
    (hash, meal) => ({
      ...hash,
      [meal.id]: setMealOptions(mealOptionSelections, meal.id),
    }),
    {}
  );

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_BASKET_RESPONSE:
    case UNDONATE_BASKET_RESPONSE:
    case UPDATE_BASKET_RESPONSE:
    case DONATE_BASKET_RESPONSE:
      return updateBasket(action.response.weeklyBasket);
    case UNDO_BASKET_EDITS:
    case SET_EDITED_BASKET:
      return updateBasket(action.basket);
    case CHANGE_MEAL_OPTION:
      return set([action.mealId, action.mealOptionGroupId], action.mealOptionId, state);
    case UNDO_MEAL_OPTIONS:
      return set(action.mealId, action.mealOptions, state);
    case CLEAR_EDITED_BASKET:
      return initialState;
    case ROUTE_CHANGE:
      if (action.routeName === Routes.ROOT_PATH_NAME) return initialState;
      return state;
    default:
      return state;
  }
};

export const selectMealOptionsForMeal = (state, mealId) => get(state, mealId, {});

const HCTokenElement = document.getElementsByName('csrf-token')[0];
export const HCToken = HCTokenElement && HCTokenElement.getAttribute('content');

export const hcAuthenticatedHeaders = () =>
  new Headers({
    'Content-Type': 'application/json',
    accept: 'application/json',
    'X-CSRF-Token': HCToken,
  });

export const BASE_URL = '/api/v2';

export const hcUrl = endpoint => `${BASE_URL}${endpoint}`;

import { CLEAR_EDITED_BASKET, UNDO_BASKET_EDITS, UNDO_MEAL_OPTIONS } from 'actions/basketEdits';
import { ADD_BASKET_REQUEST, UPDATE_BASKET_REQUEST } from 'actions/baskets';
import { SHOW_CUSTOMIZE_IT_CARD, CLOSE_CUSTOMIZE_IT_CARD } from 'actions/customizeIt';

const initialState = {};

export default (state = initialState, action) => {
  const { mealId, type, isWithinSlideout } = action;

  switch (type) {
    case SHOW_CUSTOMIZE_IT_CARD:
      return { [mealId]: { exists: true, isWithinSlideout } };
    case UNDO_MEAL_OPTIONS:
    case UNDO_BASKET_EDITS:
    case ADD_BASKET_REQUEST:
    case UPDATE_BASKET_REQUEST:
    case CLEAR_EDITED_BASKET:
    case CLOSE_CUSTOMIZE_IT_CARD:
      return initialState;
    default:
      return state;
  }
};

export const selectCustomizeItForMealId = (state, mealId) => state[mealId] || false;

import {
  fetchWrapper,
  handleResponse,
  hcUrl,
  buildOpts,
  formatBody,
} from 'services/RequestService/helpers';

const createExperimentAssignment = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/experiment_assignments'),
    buildOpts({
      method: 'POST',
      body: formatBody({
        experiment: {
          experimentName: params.name,
        },
      }),
    })
  );
  return handleResponse(response);
};

const getExperiment = async ({ params }) => {
  const response = await fetchWrapper(hcUrl(`/experiments/${params.name}`));
  return handleResponse(response);
};

export { createExperimentAssignment, getExperiment };

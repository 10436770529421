import { decamelize } from 'helpers/camelize';
import {
  fetchWrapper,
  handleResponse,
  objectToQueryString,
  hcUrl,
  buildOpts,
  formatBody,
} from 'services/RequestService/helpers';

export const getMealSelections = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl(`/user/meal_selections?${objectToQueryString(decamelize(params))}`),
    buildOpts()
  );
  return handleResponse(response);
};

export const getMealNote = async ({ params }) => {
  const { mealId } = params;
  const response = await fetchWrapper(hcUrl(`/meals/${mealId}/meal_note`), buildOpts());

  return handleResponse(response);
};

export const addMealNote = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/meal_notes'),
    buildOpts({
      method: 'POST',
      body: formatBody({ meal_note: params }),
    })
  );

  return handleResponse(response);
};

export const updateMealNote = async ({ params }) => {
  const { mealNoteId, description } = params;
  const response = await fetchWrapper(
    hcUrl(`/meal_notes/${mealNoteId}`),
    buildOpts({
      method: 'PATCH',
      body: formatBody({ meal_note: { description } }),
    })
  );

  return handleResponse(response);
};

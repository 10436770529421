import { getReceipt } from 'actions/baskets';
import { mapBasketStateToReceiptParams } from 'helpers/requestTransformers';
import * as fromDashboard from 'reducers/dashboard';

export const SET_EDITED_BASKET = 'SET_EDITED_BASKET';
export const UNDO_BASKET_EDITS = 'UNDO_BASKET_EDITS';
export const CLEAR_EDITED_BASKET = 'CLEAR_EDITED_BASKET';

export const SET_BASKET_SERVINGS = 'SET_BASKET_SERVINGS';
export const UPDATE_MEAL_SERVINGS = 'UPDATE_MEAL_SERVINGS';
export const ADD_PAIRED_MEALS = 'ADD_PAIRED_MEALS';
export const REMOVE_PAIRED_MEAL = 'REMOVE_PAIRED_MEAL';

export const ADD_MEAL = 'ADD_MEAL';
export const REMOVE_MEAL = 'REMOVE_MEAL';

export const CHANGE_MEAL_OPTION = 'CHANGE_MEAL_OPTION';
export const UNDO_MEAL_OPTIONS = 'UNDO_MEAL_OPTIONS';
export const ADD_SERVINGS = 'ADD_SERVINGS';
export const REMOVE_SERVINGS = 'REMOVE_SERVINGS';

export const updateBasketReceipt = (menuSlug, includeDiscounts = false) => {
  return async (dispatch, getState) => {
    const state = getState();
    const basket = fromDashboard.selectBasket(state, menuSlug);
    const mealServings = fromDashboard.selectMealServingsForBasketEdits(state);
    const selectedMealOptions = fromDashboard.selectMealOptionsForBasketEdits(state);
    const selectedPairings = fromDashboard.selectPairingsForBasketEdits(state);
    const receiptParams = mapBasketStateToReceiptParams(
      basket,
      {
        mealServings,
        selectedMealOptions,
        selectedPairings,
      },
      includeDiscounts
    );

    return await dispatch(getReceipt(receiptParams));
  };
};

export const setEditedBasket = ({ basket, selectedPairings }) => ({
  type: SET_EDITED_BASKET,
  basket,
  selectedPairings,
});

export const undoBasketEdits = ({ basket, selectedPairings }) => ({
  type: UNDO_BASKET_EDITS,
  basket,
  selectedPairings,
});

export const clearEditedBasket = () => ({
  type: CLEAR_EDITED_BASKET,
});

export const setBasketServings = mealServings => ({
  type: SET_BASKET_SERVINGS,
  mealServings,
});

export const updateMealServings = ({ mealId, menuSlug, mealServings }) => ({
  type: UPDATE_MEAL_SERVINGS,
  mealServings,
  menuSlug,
  mealId,
});

export const addMeal = ({ mealId, startServings, menuSlug }) => ({
  type: ADD_MEAL,
  menuSlug,
  mealId,
  startServings,
});

export const removeMeal = ({ mealId, menuSlug }) => ({
  type: REMOVE_MEAL,
  menuSlug,
  mealId,
});

export const addServings = ({ mealId, menuSlug, mealServings }) => ({
  type: ADD_SERVINGS,
  menuSlug,
  mealId,
  mealServings,
});

export const addPairedMeals = ({ menuSlug, hostMealId, pairedMealId }) => ({
  type: ADD_PAIRED_MEALS,
  menuSlug,
  hostMealId,
  pairedMealId,
});

export const removePairedMeal = ({ menuSlug, hostMealId }) => ({
  type: REMOVE_PAIRED_MEAL,
  menuSlug,
  hostMealId,
});

export const removeServings = ({ mealId, menuSlug, mealServings }) => ({
  type: REMOVE_SERVINGS,
  menuSlug,
  mealId,
  mealServings,
});

export const changeMealOption = ({ mealId, menuSlug, mealOptionGroupId, mealOptionId }) => ({
  type: CHANGE_MEAL_OPTION,
  menuSlug,
  mealId,
  mealOptionGroupId,
  mealOptionId,
});

export const undoMealOptions = (mealId, mealOptions) => ({
  type: UNDO_MEAL_OPTIONS,
  mealId,
  mealOptions,
});

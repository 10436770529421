import classNames from 'classnames';

const PaleoFriendly = ({ className = '' }) => {
  return (
    <svg
      className={classNames('dietary-tag', className)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden={true}
    >
      <title>Paleo-Friendly</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.729 10.1882L13.6489 8.10809L15.1682 6.46324C14.8717 5.75411 15.0426 4.90543 15.6082 4.3398C16.345 3.60295 17.5622 3.5361 18.3255 4.29939C18.5451 4.51895 18.6963 4.77913 18.7824 5.05466C19.0579 5.1407 19.3181 5.29196 19.5376 5.51152C20.3009 6.27482 20.2341 7.49199 19.4972 8.22883C18.9316 8.79446 18.0829 8.96529 17.3738 8.66886L15.729 10.1882ZM18.5147 5.7577C18.6964 5.80651 18.8665 5.90108 19.0073 6.04185C19.4536 6.48817 19.4355 7.22987 18.9669 7.6985C18.4983 8.16713 17.7566 8.18522 17.3103 7.73891C17.3046 7.73329 17.2991 7.72763 17.2936 7.72192L15.7496 9.14811L14.689 8.08745L16.1151 6.5434C16.1094 6.53793 16.1037 6.53239 16.0981 6.52678C15.6518 6.08046 15.6699 5.33876 16.1385 4.87013C16.6072 4.4015 17.3489 4.38341 17.7952 4.82972C17.936 4.97049 18.0305 5.14065 18.0793 5.32236C18.1142 5.45219 18.1257 5.58791 18.114 5.72302C18.2491 5.71133 18.3848 5.72283 18.5147 5.7577Z"
        fill="#004400"
      />
      <path
        d="M14.1586 8.61755L15.2192 9.67821C15.2192 9.67821 10.2995 22.0486 6.20362 19.0474C5.57359 18.5857 5.25104 18.2632 4.7894 17.6332C1.7882 13.5373 14.1586 8.61755 14.1586 8.61755Z"
        fill="#99CE99"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3399 7.73831L16.0984 9.49686L15.9161 9.95541L15.2191 9.67825L14.1585 8.61759L13.8813 7.92068L14.3399 7.73831ZM14.1585 8.61759C13.8813 7.92068 13.8815 7.92059 13.8813 7.92068L13.8781 7.92198L13.8703 7.92508L13.8418 7.93658C13.817 7.94659 13.781 7.96126 13.7346 7.9804C13.6416 8.01869 13.5069 8.07491 13.3372 8.14767C12.998 8.29314 12.5182 8.505 11.9524 8.77204C10.8238 9.30469 9.33976 10.0631 7.94371 10.958C6.56375 11.8426 5.19918 12.9044 4.37594 14.0619C3.9602 14.6464 3.6515 15.2982 3.58373 15.9975C3.51398 16.7172 3.70622 17.424 4.18435 18.0765C4.6913 18.7684 5.06839 19.1454 5.76025 19.6524C6.41278 20.1305 7.11953 20.3228 7.83925 20.253C8.53854 20.1852 9.19032 19.8765 9.77487 19.4608C10.9324 18.6376 11.9941 17.273 12.8787 15.893C13.7736 14.497 14.532 13.0129 15.0647 11.8843C15.3317 11.3185 15.5436 10.8387 15.6891 10.4995C15.7618 10.3298 15.818 10.1951 15.8563 10.1022C15.8755 10.0557 15.8901 10.0197 15.9002 9.99495L15.9117 9.96641L15.9148 9.95867L15.9161 9.95541C15.9161 9.95519 15.9161 9.95541 15.2191 9.67825C15.2191 9.67825 10.2994 22.0486 6.20354 19.0474C5.57352 18.5858 5.25096 18.2632 4.78932 17.6332C1.78812 13.5373 14.1585 8.61759 14.1585 8.61759Z"
        fill="#004400"
      />
    </svg>
  );
};

PaleoFriendly.propTypes = {
  className: PropTypes.string,
};

export default PaleoFriendly;

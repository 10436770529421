export const numberToDollarString = number => {
  return number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const dollarStringToNumber = string => Number(string.slice(1));

// The API usually returns strings like "$9.99", but sometimes returns 0 or null
// This standardizes those cases to "$0.00" so everything is a dollar string
export const maybeNullDollarString = stringOrNull => stringOrNull || '$0.00';

export const trimEmptyCents = string => string.replace('.00', '');

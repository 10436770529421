export const EARNED_CREDITS_PATH = '/account/earn-credits';
export const IOS_MOBILE_PATH = '/ios-app';
export const ANDROID_MOBILE_PATH = '/android-app';
export const TERMS_PATH = 'https://www.homechef.com/terms';
export const PRIVACY_POLICY_PATH = 'https://cook.homechef.com/privacy-policy';
export const FAQ_PATH = '/faq';
export const SIGN_IN_PATH = '/users/sign_in';
export const OUR_MENU_PATH = '/our-menu';
export const INTRO_TEMPO_PATH = '/signup/intro/tempo';
export const TEMPO_SUPPORT_PATH = 'https://support.tempomeals.com/hc/en-us';
export const HC_SUPPORT_PATH = 'https://support.homechef.com/hc/en-us';
export const AFFILIATES_PATH = '/affiliates';
export const TEMPO_RECYCLE_PATH = 'https://eat.tempomeals.com/recycle';
export const TEMPO_STUDENTS_PATH = 'https://connect.studentbeans.com/v4/tempo-by-homechef/us';
export const TEMPO_HEROES_PATH = 'https://eat.tempomeals.com/heroes';
export const FOOD_WASTE_PATH = 'https://cook.homechef.com/foodwaste';
export const ACCESSIBILITY_PATH = 'https://www.homechef.com/accessibility';
export const TEMPO_ABOUT_US_PATH = 'https://eat.tempomeals.com/about-us';
export const CAREERS_PATH = 'https://www.homechef.com/careers';
export const LEGAL_PATH = 'https://www.homechef.com/legal';
export const APPLE_STORE_PATH =
  'https://apps.apple.com/us/app/home-chef-meal-kit-delivery/id994918972';
export const GOOGLE_PLAY_PATH =
  'https://play.google.com/store/apps/details?id=com.homechef.android&pli=1';
export const TEMPO_INSTAGRAM_PATH = 'https://www.instagram.com/tempomeals/';
export const TEMPO_FACEBOOK_PATH = 'https://www.facebook.com/tempomeals';
export const TEMPO_PATH = 'https://www.tempomeals.com/';

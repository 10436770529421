import {
  fetchWrapper,
  handleResponse,
  hcUrl,
  buildOpts,
  transformMenuSearchMeals,
  objectToQueryString,
} from 'services/RequestService/helpers';

export const getMenuSearchMeals = async ({
  params: { searchTerm, limit, offset, planId, page },
}) => {
  const response = await fetchWrapper(
    hcUrl(
      `/meal_search?${objectToQueryString({
        search_term: searchTerm,
        limit,
        offset,
        plan_id: planId,
        page,
      })}`
    ),
    buildOpts({
      method: 'GET',
    })
  );
  return handleResponse(response, transformMenuSearchMeals);
};

import {
  GET_PAUSE_REASONS_REQUEST,
  GET_PAUSE_REASONS_RESPONSE,
  GET_PAUSE_REASONS_ERROR,
} from 'actions/account';

const initialState = {
  isLoading: false,
  isError: false,
  results: [],
  pauseReasons: [],
};

const activeReasonCodes = [
  'reasonSiteDifficultToUse',
  'reasonDeliveryIssues',
  'reasonPricingPersonalBudget',
  'reasonFoodPersonalDiet',
  'reasonFoodVariety',
  'reasonScheduleCookingTime',
  'reasonFoodQuality',
  'reasonScheduleLifeChanges',
  'reasonScheduleTravel',
];

const flatReasonsList = groupedReasonsResponse =>
  Object.assign({}, ...groupedReasonsResponse.map(group => group.reasons));

const transformPauseReasons = response => {
  const collection = [];
  const allReasons = flatReasonsList(response);
  activeReasonCodes.forEach(code => collection.push({ code: code, text: allReasons[code] }));
  return collection;
};

export default (state = initialState, action) => {
  const { type, response } = action;
  switch (type) {
    case GET_PAUSE_REASONS_REQUEST:
      return { ...state, isLoading: true, isError: false, pauseReasons: [] };
    case GET_PAUSE_REASONS_RESPONSE:
      return {
        ...state,
        isLoading: false,
        groupedPauseReasons: response.pauseReasons,
        pauseReasons: transformPauseReasons(response.pauseReasons),
        inlineDeflectors: response.inlineDeflectors,
      };
    case GET_PAUSE_REASONS_ERROR:
      return { ...state, isLoading: false, isError: true, pauseReasons: [] };
    default:
      return state;
  }
};

import {
  GET_QUICK_FILTERS_RESPONSE,
  GET_QUICK_FILTERS_ERROR,
  GET_QUICK_FILTERS_REQUEST,
} from 'actions/quickFilters';

const initialState = {
  loading: false,
  failed: false,
  data: [],
};

const toMealFilter = quickFilter => {
  const defaultValue = { [quickFilter.filterValue]: true };

  const filters =
    quickFilter.filterValues?.length > 0
      ? quickFilter.filterValues.reduce((acc, current) => {
          acc[current] = true;
          return acc;
        }, {})
      : defaultValue;

  return {
    filter: `${quickFilter.filterName}Filter`,
    value: filters,
  };
};

const transformQuickFilters = quickFilters => {
  return quickFilters.map(qf => ({ ...qf, toMealFilter: toMealFilter(qf) }));
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_QUICK_FILTERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_QUICK_FILTERS_RESPONSE:
      return {
        ...state,
        loading: false,
        data: transformQuickFilters(action.response.quickFilters),
      };
    case GET_QUICK_FILTERS_ERROR:
      return {
        ...state,
        loading: false,
        failed: true,
        data: [],
      };
    default:
      return state;
  }
};

export const selectQuickFilters = state => state.dashboard.quickFilters.data;

export const SHOW_CUSTOMIZE_IT_CARD = 'SHOW_CUSTOMIZE_IT_CARD';
export const CLOSE_CUSTOMIZE_IT_CARD = 'CLOSE_CUSTOMIZE_IT_CARD';

export const showCustomizeItCard = (mealId, isWithinSlideout) => ({
  type: SHOW_CUSTOMIZE_IT_CARD,
  mealId,
  isWithinSlideout,
});

export const closeCustomizeItCard = () => ({
  type: CLOSE_CUSTOMIZE_IT_CARD,
});

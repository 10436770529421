import identity from 'lodash/identity';
import qs from 'qs';

import { camelize, decamelize } from 'helpers/camelize';

import { hcAuthenticatedHeaders } from './authorization';
import { getJsonParseErrorClass } from './errors';

export const buildOpts = opts => ({
  headers: hcAuthenticatedHeaders(),
  credentials: 'same-origin',
  method: 'GET',
  ...opts,
});

export const handleResponse = async (response, transformResponse = identity) => {
  const responseBody = await response.text();

  if (!responseBody) return response;

  let parsedJson;

  try {
    parsedJson = camelize(JSON.parse(responseBody));
  } catch (error) {
    const errorClass = getJsonParseErrorClass(responseBody);
    throw new errorClass(error, response, responseBody);
  }

  if (response.ok) parsedJson = transformResponse(parsedJson);

  return { ok: response.ok, status: response.status, ...parsedJson };
};

export const formatBody = (body, ignoreHashRegex = false) =>
  JSON.stringify(decamelize(body, ignoreHashRegex));

export const objectToQueryString = obj => qs.stringify(obj, { arrayFormat: 'brackets' });

export const parseQueryString = str => qs.parse(str, { ignoreQueryPrefix: true });

import { fetchWrapper, handleResponse, hcUrl, buildOpts } from 'services/RequestService/helpers';

const createSetupIntents = async () => {
  const response = await fetchWrapper(
    hcUrl('/stripe/setup_intents'),
    buildOpts({
      method: 'POST',
    })
  );
  return handleResponse(response);
};

export { createSetupIntents };

import { makeApiRequest } from 'actions/api';
import redirectToLogin from 'helpers/redirectToLogin';
import * as fromCookbook from 'reducers/cookbook';
import RequestService from 'services/RequestService';

export const ADD_REVIEW_FOR_COOKBOOK_REQUEST = 'ADD_REVIEW_FOR_COOKBOOK_REQUEST';
export const ADD_REVIEW_FOR_COOKBOOK_RESPONSE = 'ADD_REVIEW_FOR_COOKBOOK_RESPONSE';
export const ADD_REVIEW_FOR_COOKBOOK_ERROR = 'ADD_REVIEW_FOR_COOKBOOK_ERROR';

export const ADD_REVIEW = 'ADD_REVIEW';
export const ADD_REVIEW_REQUEST = 'ADD_REVIEW_REQUEST';
export const ADD_REVIEW_RESPONSE = 'ADD_REVIEW_RESPONSE';
export const ADD_REVIEW_ERROR = 'ADD_REVIEW_ERROR';

export const UPDATE_REVIEW_FOR_COOKBOOK_REQUEST = 'UPDATE_REVIEW_FOR_COOKBOOK_REQUEST';
export const UPDATE_REVIEW_FOR_COOKBOOK_RESPONSE = 'UPDATE_REVIEW_FOR_COOKBOOK_RESPONSE';
export const UPDATE_REVIEW_FOR_COOKBOOK_ERROR = 'UPDATE_REVIEW_FOR_COOKBOOK_ERROR';

export const UPDATE_REVIEW = 'UPDATE_REVIEW';
export const UPDATE_REVIEW_REQUEST = 'UPDATE_REVIEW_REQUEST';
export const UPDATE_REVIEW_RESPONSE = 'UPDATE_REVIEW_RESPONSE';
export const UPDATE_REVIEW_ERROR = 'UPDATE_REVIEW_ERROR';

export const GET_PENDING_REVIEWS = 'GET_PENDING_REVIEWS';
export const GET_PENDING_REVIEWS_REQUEST = 'GET_PENDING_REVIEWS_REQUEST';
export const GET_PENDING_REVIEWS_RESPONSE = 'GET_PENDING_REVIEWS_RESPONSE';
export const GET_PENDING_REVIEWS_ERROR = 'GET_PENDING_REVIEWS_ERROR';

export const REVIEW_CHANGE_FOR_COOKBOOK = 'REVIEW_CHANGE_FOR_COOKBOOK';
export const REVIEW_CHANGE = 'REVIEW_CHANGE';

export const handleReviewChangeForCookbook = (mealSelectionId, review) => ({
  type: REVIEW_CHANGE_FOR_COOKBOOK,
  mealSelectionId,
  review,
});

export const addReviewRequest = (mealSelectionId, review) => ({
  type: ADD_REVIEW_FOR_COOKBOOK_REQUEST,
  mealSelectionId,
  review,
});

export const addReviewResponse = (mealSelectionId, response) => ({
  type: ADD_REVIEW_FOR_COOKBOOK_RESPONSE,
  mealSelectionId,
  response,
});

export const addReviewError = (mealSelectionId, error) => ({
  type: ADD_REVIEW_FOR_COOKBOOK_ERROR,
  mealSelectionId,
  error,
});

export function addReviewForCookbook(mealSelectionId, review) {
  return (dispatch, _, { requestService }) => {
    dispatch(addReviewRequest(mealSelectionId, review));
    const params = { mealSelectionId, review };

    return requestService
      .addReviewForCookbook({ params })
      .then(response => {
        if (response.status === 401) return redirectToLogin();
        if (!response.ok) return dispatch(addReviewError(mealSelectionId, response.error));
        return dispatch(addReviewResponse(mealSelectionId, response));
      })
      .catch(error => dispatch(addReviewError(mealSelectionId, error)));
  };
}

export const updateReviewRequest = (mealSelectionId, review, reviewId) => ({
  type: UPDATE_REVIEW_FOR_COOKBOOK_REQUEST,
  mealSelectionId,
  review,
  reviewId,
});

export const updateReviewError = (mealSelectionId, reviewId, error) => ({
  type: UPDATE_REVIEW_FOR_COOKBOOK_ERROR,
  error,
  mealSelectionId,
  reviewId,
});

export const updateReviewResponse = (mealSelectionId, reviewId, response) => ({
  type: UPDATE_REVIEW_FOR_COOKBOOK_RESPONSE,
  mealSelectionId,
  reviewId,
  response,
});

export function updateReviewForCookbook(mealSelectionId, review) {
  return (dispatch, getState, { requestService }) => {
    const reviewId = fromCookbook.selectReview(getState(), mealSelectionId).id;
    dispatch(updateReviewRequest(mealSelectionId, review, reviewId));

    const params = { review, reviewId };
    return requestService
      .updateReview({ params })
      .then(response => {
        if (response.status === 401) return redirectToLogin();
        if (!response.ok)
          return dispatch(updateReviewError(mealSelectionId, reviewId, response.error));
        return dispatch(updateReviewResponse(mealSelectionId, reviewId, response));
      })
      .catch(error => dispatch(updateReviewError(mealSelectionId, reviewId, error)));
  };
}

export const handleReviewChange = (mealId, review) => ({
  type: REVIEW_CHANGE,
  mealId,
  review,
});

export const addReview = params =>
  makeApiRequest({
    requestType: ADD_REVIEW,
    method: RequestService.addReview,
    params,
  });

export const updateReview = (reviewId, review) =>
  makeApiRequest({
    requestType: UPDATE_REVIEW,
    method: RequestService.updateReview,
    params: { reviewId, review },
  });

export const getPendingReviews = params =>
  makeApiRequest({
    requestType: GET_PENDING_REVIEWS,
    method: RequestService.getPendingReviews,
    params,
  });

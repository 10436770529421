import {
  fetchWrapper,
  handleResponse,
  hcUrl,
  buildOpts,
  formatBody,
} from 'services/RequestService/helpers';

export const createAddress = async ({ params: { address } }) => {
  const response = await fetchWrapper(
    hcUrl('/user/address'),
    buildOpts({
      method: 'POST',
      body: formatBody({ address }),
    })
  );
  return handleResponse(response);
};

export const destroyAddress = async ({ params: { id } }) => {
  const response = await fetchWrapper(
    hcUrl('/user/address'),
    buildOpts({
      method: 'DELETE',
      body: formatBody({ id }),
    })
  );
  return handleResponse(response);
};

export const updateAddress = async ({ params: { address } }) => {
  const response = await fetchWrapper(
    hcUrl('/user/address'),
    buildOpts({
      method: 'PATCH',
      body: formatBody({ address }),
    })
  );

  return handleResponse(response);
};

export const setDefaultAddress = async ({ params: { id } }) => {
  const response = await fetchWrapper(
    hcUrl('/user/default_address'),
    buildOpts({
      method: 'PATCH',
      body: formatBody({ id }),
    })
  );

  return handleResponse(response);
};

// menu category slugs
export const MEAL_KITS_SLUG = 'meal-kits';
export const EASY_MEAL_KITS_SLUG = 'easy-prep-kits';
export const LUNCH_SLUG = 'lunch';

// menu category for meal
export const PROTEIN = 'protein';

// serving variants
export const CONTAINER = 'container';
export const MEAL_KIT = 'meal kit';
export const SERVING = 'serving';
export const SERVINGS = 'servings';

export const STANDARD_AS_PACKAGED_ACCESSIBILITY_LABEL =
  'Nutritional values for uncooked ingredients';

export const getNutritionFactInfoLegend = singleColumn => {
  return [
    {
      groupTitle: 'fat',
      fat: 'Total Fat',
      saturatedFat: 'Saturated Fat',
      transFat: 'Trans Fat',
    },
    {
      groupTitle: 'cholesterol',
      cholesterol: 'Cholesterol',
    },
    {
      groupTitle: 'sodium',
      sodium: 'Sodium',
    },
    {
      groupTitle: 'carbohydrates',
      carbohydrates: `Total Carb${singleColumn ? 'ohydrate' : '.'}`,
      dietaryFiber: 'Dietary Fiber',
      sugar: 'Total Sugars',
      addedSugars: 'Incl. Added Sugars',
    },
    {
      groupTitle: 'protein',
      protein: 'Protein',
    },
  ];
};

export const NO_PERCENT_DATA_REQUIRED = ['protein', 'sugars', 'transFat'];

export const VITAMIN_INFO_LEGEND = [
  { vitaminD: 'Vitamin D' },
  { calcium: 'Calcium' },
  { iron: 'Iron' },
  { potassium: 'Potassium' },
];

export const ADDED_SUGARS = 'addedSugars';

import { makeApiRequest } from 'actions/api';
import RequestService from 'services/RequestService';

export const GET_MEAL_ADDONS = 'GET_MEAL_ADDONS';
export const GET_MEAL_ADDONS_REQUEST = 'GET_MEAL_ADDONS_REQUEST';
export const GET_MEAL_ADDONS_RESPONSE = 'GET_MEAL_ADDONS_RESPONSE';
export const GET_MEAL_ADDONS_ERROR = 'GET_MEAL_ADDONS_ERROR';

export const getMealAddons = () =>
  makeApiRequest({
    requestType: GET_MEAL_ADDONS,
    method: RequestService.getMealAddons,
  });

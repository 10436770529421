import {
  GET_PAUSE_DEFLECTOR_MEALS_RESPONSE,
  GET_PAUSE_DEFLECTOR_MEALS_REQUEST,
  GET_PAUSE_DEFLECTOR_MEALS_ERROR,
} from 'actions/meals';

const initialState = {
  fetching: false,
  fetchFailed: false,
  results: [],
};

export default (state = initialState, action) => {
  const { response, type } = action;

  switch (type) {
    case GET_PAUSE_DEFLECTOR_MEALS_REQUEST:
      return { ...state, fetching: true };
    case GET_PAUSE_DEFLECTOR_MEALS_RESPONSE:
      return { ...state, results: response.meals, fetching: false };
    case GET_PAUSE_DEFLECTOR_MEALS_ERROR:
      return { ...state, fetching: false, fetchFailed: true };
    default:
      return state;
  }
};

export const selectPauseDeflectorMealsResults = state => state.results;
export const selectPauseDeflectorMealsFetching = state => state.fetching;
export const selectPauseDeflectorMealsFailed = state => state.fetchFailed;

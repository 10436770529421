import { useState, useEffect } from 'react';

function getViewportWidth() {
  const { innerWidth: width } = window;
  return {
    width,
  };
}

export default function useViewportWidth() {
  const [viewportWidth, setViewportWidth] = useState(getViewportWidth());

  useEffect(() => {
    function handleResize() {
      setViewportWidth(getViewportWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return viewportWidth;
}

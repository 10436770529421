export const ERROR_MESSAGE = 'Sorry, an unexpected error occurred. Please try again.';
export const SUBTOTAL_ERROR_MESSAGE =
  'You are below the order minimum of $50. Please add more meals or a protein pack to continue. All other extras do not count towards the order minimum.';
export const EMPTY_BOX_ERROR_MESSAGE = 'Add meals to your box to continue to checkout.';
export const CONFIRM_NSO_ORDER_MESSAGE = 'Are you sure you want to confirm your order?';
export const YOUR_PICKS_HEADING = 'Your Picks';
export const YOUR_PICKS_SUBHEADING =
  'Your recipient will be able to swap out recipes of equal or lesser value before receiving their order.';
export const CHANGE_MENU_MESSAGE =
  'Your meal selections will not be saved if you switch menu weeks. Are you sure you want to switch the menu week for your order?';

// string values from `box#display_status`
export const BOX_CREATED_STATUS = 'box_created';
export const MENU_SELECTED_STATUS = 'menu_selected';
export const SENDER_CONFIRMED_STATUS = 'sender_confirmed';
export const PROCESSED_STATUS = 'processed';
export const RECIPIENT_CONFIRMED_STATUS = 'recipient_confirmed';
export const SHIPPED_STATUS = 'shipped';
export const DELIVERED_STATUS = 'delivered';
export const BOX_BASKET_ACTIVE_STATUS = 'active';

export const IN_PROGRESS_STATUSES = [BOX_CREATED_STATUS, MENU_SELECTED_STATUS];

export const SENT_BOX_STATUSES = [
  SENDER_CONFIRMED_STATUS,
  PROCESSED_STATUS,
  RECIPIENT_CONFIRMED_STATUS,
  SHIPPED_STATUS,
  DELIVERED_STATUS,
  BOX_BASKET_ACTIVE_STATUS,
];

export const QUICK_FILTERS = [
  {
    displayName: 'Oven-Ready',
    filterName: 'mealType',
    filterValue: 'Oven-Ready',
    filterValues: ['Oven-Ready', 'Oven-Ready Plus', 'Oven-Ready Family'],
    iconUrl: 'https://asset.homechef.com/quick_filters/Oven_Ready.svg',
    toMealFilter: {
      filter: 'mealTypeFilter',
      value: {
        'Oven-Ready': true,
        'Oven-Ready Plus': true,
        'Oven-Ready Family': true,
      },
    },
  },
  {
    displayName: 'Desserts',
    filterName: 'mealType',
    filterValue: 'Dessert',
    filterValues: null,
    iconUrl: 'https://asset.homechef.com/quick_filters/Desserts.svg',
    toMealFilter: {
      filter: 'mealTypeFilter',
      value: {
        Dessert: true,
      },
    },
  },
  {
    displayName: 'Fresh Start',
    filterName: 'mealBadge',
    filterValue: 'Fresh Start',
    filterValues: null,
    iconUrl: 'https://asset.homechef.com/quick_filters/Fresh_Start_Badge.svg',
    toMealFilter: {
      filter: 'mealBadgeFilter',
      value: {
        'Fresh Start': true,
      },
    },
  },
];

import { GET_MEALS_REQUEST } from 'actions/meals';
import {
  ADD_REVIEW_FOR_COOKBOOK_REQUEST,
  ADD_REVIEW_FOR_COOKBOOK_ERROR,
  UPDATE_REVIEW_FOR_COOKBOOK_ERROR,
  UPDATE_REVIEW_FOR_COOKBOOK_REQUEST,
} from 'actions/reviews';

const initialState = {
  isError: false,
};

export default (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case GET_MEALS_REQUEST:
      return initialState;
    case ADD_REVIEW_FOR_COOKBOOK_REQUEST:
    case UPDATE_REVIEW_FOR_COOKBOOK_REQUEST:
      return initialState;
    case ADD_REVIEW_FOR_COOKBOOK_ERROR:
    case UPDATE_REVIEW_FOR_COOKBOOK_ERROR:
      return { isError: true };
    default:
      return state;
  }
};

import { GET_OPT_INS_REQUEST, GET_OPT_INS_RESPONSE, GET_OPT_INS_ERROR } from 'actions/optIns';
import { UPDATE_OPT_IN_SELECTIONS_REQUEST, UPDATE_OPT_IN_SELECTIONS_RESPONSE } from 'actions/user';

const initialState = {
  results: [],
  loading: false,
  failed: false,
  updating: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OPT_INS_REQUEST:
      return { ...state, loading: true, failed: false };
    case GET_OPT_INS_RESPONSE:
      return { ...state, loading: false, results: action.response.optIns };
    case GET_OPT_INS_ERROR:
      return { ...state, loading: false, failed: true };
    case UPDATE_OPT_IN_SELECTIONS_REQUEST:
      return { ...state, updating: true };
    case UPDATE_OPT_IN_SELECTIONS_RESPONSE:
      return { ...state, updating: false };
    default:
      return state;
  }
};

export const selectOptInsResults = state => state.results;
export const selectOptInsLoading = state => state.loading;
export const selectOptInsFailed = state => state.failed;
export const selectOptInsUpdating = state => state.updating;

const KetoFriendly = ({ className = '' }) => {
  return (
    <svg viewBox="0 0 12 16" className={className} role="presentation" pointerEvents="none">
      <title>Keto-Friendly</title>
      <path
        d="M11.1399 9.31122C11.1399 13.227 8.76059 15.0887 6.15005 15.0887C3.5395 15.0887 1.16187 13.2287 1.16187 9.31122C1.16187 5.39376 3.54059 0.90686 6.15168 0.90686C8.76278 0.90686 11.1399 5.39649 11.1399 9.31122Z"
        fill="#99CE99"
      />
      <path
        d="M6.15 15.5C3.46255 15.5 0.75 13.5871 0.75 9.31292C0.75 5.32073 3.15873 0.5 6.15 0.5C9.14127 0.5 11.55 5.32073 11.55 9.31292C11.5489 13.5871 8.83691 15.5 6.15 15.5ZM6.15 1.31818C3.92346 1.31818 1.56818 5.42764 1.56818 9.31292C1.56818 13.2751 4.03636 14.6818 6.15 14.6818C8.26364 14.6818 10.7318 13.2751 10.7318 9.31292C10.7307 5.42764 8.376 1.31818 6.15 1.31818Z"
        fill="#004400"
      />
      <path
        d="M6.14995 12.6064C7.53629 12.6064 8.66013 11.4825 8.66013 10.0962C8.66013 8.70984 7.53629 7.586 6.14995 7.586C4.76362 7.586 3.63977 8.70984 3.63977 10.0962C3.63977 11.4825 4.76362 12.6064 6.14995 12.6064Z"
        fill="#004400"
      />
      <path
        d="M6.14999 13.0154C5.57261 13.0154 5.0082 12.8442 4.52813 12.5234C4.04805 12.2026 3.67388 11.7467 3.45293 11.2133C3.23198 10.6798 3.17417 10.0929 3.28681 9.52661C3.39945 8.96032 3.67748 8.44015 4.08575 8.03188C4.49402 7.62361 5.01418 7.34558 5.58046 7.23294C6.14675 7.1203 6.73372 7.17812 7.26714 7.39907C7.80057 7.62002 8.2565 7.99419 8.57727 8.47426C8.89805 8.95434 9.06926 9.51875 9.06926 10.0961C9.06839 10.8701 8.76055 11.6121 8.21327 12.1594C7.66598 12.7067 6.92396 13.0145 6.14999 13.0154ZM6.14999 7.99721C5.73443 7.99721 5.3282 8.12045 4.98268 8.35132C4.63716 8.58219 4.36786 8.91032 4.20883 9.29424C4.0498 9.67817 4.0082 10.1006 4.08927 10.5082C4.17034 10.9158 4.37045 11.2902 4.66429 11.584C4.95813 11.8778 5.33251 12.078 5.74009 12.159C6.14766 12.2401 6.57011 12.1985 6.95404 12.0395C7.33796 11.8804 7.66611 11.6111 7.89698 11.2656C8.12785 10.9201 8.25108 10.5139 8.25108 10.0983C8.25108 9.54087 8.02978 9.00622 7.63581 8.61184C7.24185 8.21747 6.70743 7.99561 6.14999 7.99503V7.99721Z"
        fill="#004400"
      />
    </svg>
  );
};

KetoFriendly.propTypes = {
  className: PropTypes.string,
};

export default KetoFriendly;

import classNames from 'classnames';

const GlutenSmart = ({ className }) => {
  return (
    <svg
      className={classNames('dietary-tag', className)}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      pointerEvents="none"
    >
      <title>Gluten-Smart</title>
      <path d="M7.39986 2.40002L4.19986 4.40002V13.6L7.39986 11.2V2.40002Z" fill="#99CE99" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.86812 0.434059V1.6723L4.10234 3.34787V0.434059C4.10234 0.318939 4.14807 0.208532 4.22947 0.12713C4.31087 0.0457286 4.42128 0 4.5364 0C4.65152 0 4.76193 0.0457286 4.84333 0.12713C4.92473 0.208532 4.97046 0.318939 4.97046 0.434059V3.35221L7.20604 1.6759V0.434059C7.20604 0.318939 7.25177 0.208532 7.33317 0.12713C7.41457 0.0457286 7.52498 0 7.6401 0C7.75522 0 7.86563 0.0457286 7.94703 0.12713C8.02843 0.208532 8.07416 0.318939 8.07416 0.434059V1.02495L8.07655 1.02316V10.6176C8.07624 10.8653 8.01861 11.1095 7.90816 11.3312C7.79772 11.5529 7.63747 11.746 7.43995 11.8955L4.97046 13.7446V15.5659C4.97046 15.6811 4.92473 15.7915 4.84333 15.8729C4.76193 15.9543 4.65152 16 4.5364 16C4.42128 16 4.31087 15.9543 4.22947 15.8729C4.14807 15.7915 4.10234 15.6811 4.10234 15.5659V13.7432L1.63918 11.8955C1.44158 11.7461 1.28127 11.553 1.17082 11.3313C1.06036 11.1096 1.00277 10.8653 1.00255 10.6176V10.4708C1.00086 10.4553 1 10.4396 1 10.4238V0.434059C1 0.318939 1.04573 0.208532 1.12713 0.12713C1.20853 0.0457286 1.31894 0 1.43406 0C1.54918 0 1.65959 0.0457286 1.74099 0.12713C1.82239 0.208532 1.86812 0.318939 1.86812 0.434059ZM7.20604 8.96483V10.4238C7.20604 10.4266 7.20607 10.4294 7.20612 10.4322V10.6176C7.2057 10.7309 7.17911 10.8425 7.12841 10.9438C7.07771 11.0451 7.00427 11.1333 6.91385 11.2015L4.97046 12.659V10.6414L7.20604 8.96483ZM7.20604 5.86174V7.8797L4.97046 9.55627V7.5387L7.20604 5.86174ZM7.20604 2.75945V4.77649L4.97046 6.45306V4.43565L7.20604 2.75945ZM1.86836 5.86237V7.88032L4.10234 9.55571V7.53815L1.86836 5.86237ZM1.86836 10.6176V8.96546L4.10234 10.6409V12.658L2.16063 11.201C2.06999 11.1331 1.99639 11.045 1.94566 10.9438C1.89494 10.8425 1.86847 10.7308 1.86836 10.6176ZM4.10234 6.4525V4.43476L1.86836 2.75939V4.77711L4.10234 6.4525Z"
        fill="#004400"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6999 0.512451C12.6999 0.236309 12.4761 0.0124512 12.1999 0.0124512C11.9238 0.0124512 11.6999 0.236309 11.6999 0.512451V14.4127L9.34934 12.1172C9.15177 11.9243 8.83521 11.9281 8.64228 12.1256C8.44935 12.3232 8.4531 12.6397 8.65066 12.8327L11.676 15.7871C11.9674 16.0717 12.4326 16.0717 12.724 15.7871L15.7493 12.8327C15.9469 12.6397 15.9507 12.3232 15.7577 12.1256C15.5648 11.9281 15.2482 11.9243 15.0507 12.1172L12.6999 14.4129V0.512451Z"
        fill="#004400"
      />
    </svg>
  );
};

GlutenSmart.propTypes = {
  className: PropTypes.string,
};

export default GlutenSmart;

import {
  fetchWrapper,
  handleResponse,
  formatBody,
  hcUrl,
  buildOpts,
} from 'services/RequestService/helpers';

export const getMessages = async () => {
  const response = await fetchWrapper(hcUrl('/user/messages'), buildOpts());
  return handleResponse(response);
};

export const updateMessage = async ({ params: { id, ...restParams } }) => {
  const response = await fetchWrapper(
    hcUrl(`/user/messages/${id}`),
    buildOpts({
      method: 'PATCH',
      body: formatBody({ messageAssignment: restParams }),
    })
  );
  return handleResponse(response);
};

import {
  fetchWrapper,
  hcUrl,
  handleResponse,
  buildOpts,
  formatBody,
} from 'services/RequestService/helpers';

const getRestartCampaign = async ({ id }) => {
  const url = id ? `/reactivate?restart_campaign_id=${id}` : '/reactivate';
  const response = await fetchWrapper(hcUrl(url), buildOpts());
  return handleResponse(response);
};

const updateRestartCampaign = async ({ params }) => {
  const response = await fetchWrapper(
    hcUrl('/reactivate'),
    buildOpts({
      method: 'PATCH',
      body: formatBody({ reactivation: params }),
    })
  );
  return handleResponse(response);
};

export { getRestartCampaign, updateRestartCampaign };
